import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import AppointmentAvailabilities from "./appointmentAvailabilities";
import AppointmentCalendar from "./appointmentCalendar";
import Section, { Steps } from "./section";
import Warning from "./warning";
import appointmentService from "../../services/appointmentService";
import dayjs from "dayjs";
import i18next from "i18next";
import { isValidArray } from "../common/check";
import { Trans } from "react-i18next";

const { confirmOtherEmployee } = window.env;

class Appointment extends Component {
  state = { calendarOpen: false, availability: null, isOtherEmployee: false };

  constructor(props) {
    super(props);

    this.appointmentCalendarControl = React.createRef();
    this.calendarScroll = React.createRef();

    this.treatments = null;
    this.employee = null;
    this.labelDayFirst = i18next.t("label.waiting");
    this.labelDaySecond = i18next.t("label.waiting");
    this.availabilitiesDayFirst = [];
    this.availabilitiesDaySecond = [];
  }

  handleEmployeeSelect(employee, forceReload) {
    const { treatments, language } = this.props;

    // Don't reload if there is no other treatment or employee is selected
    if (
      treatments === this.treatments &&
      employee === this.employee &&
      !forceReload
    )
      return;

    this.clearAvailabilities();

    appointmentService
      .fetchAvailabilitiesShort(treatments, employee, language)
      .then(availabilities => {
        this.setState({ employee, availabilities });
        this.reload(treatments, employee, availabilities);
      });
  }

  clearAvailabilities() {
    document.getElementById("chooseDate").classList.remove("open");
    document.getElementById("chooseTime").classList.remove("open");

    this.setState({
      availabilities: null,
      availabilitiesDayCalendar: null,
      calendarOpen: false
    });
    this.reload(null, null, null);
    this.showAvailabilities(null, null, null);
  }

  reload(treatments, employee, availabilities) {
    if (
      !this.appointmentCalendarControl ||
      !this.appointmentCalendarControl.current
    )
      return;

    let dateCalendarMinimal = null;
    if (isValidArray(availabilities, 1))
      dateCalendarMinimal = availabilities[
        availabilities.length - 1
      ].Date.clone().add(1, "days");

    this.appointmentCalendarControl.current.preloadCalendar(
      treatments,
      employee,
      dateCalendarMinimal,
      this.props.language
    );
  }

  showAvailabilities(treatments, employee, availabilities) {
    if (
      availabilities &&
      (availabilities.length === 0 || availabilities.IsError)
    ) {
      this.availabilitiesDayFirst.IsError = true;
      this.availabilitiesDaySecond.IsError = true;

      return;
    }

    if (!availabilities) {
      this.labelDayFirst = i18next.t("label.waiting");
      this.labelDaySecond = i18next.t("label.waiting");

      this.availabilitiesDayFirst = [];
      this.availabilitiesDaySecond = [];

      return;
    }

    dayjs.locale(this.props.language);

    this.treatments = treatments;
    this.employee = employee;
    this.availabilitiesDayFirst = [];
    this.availabilitiesDaySecond = [];

    const dateFirst = availabilities[0].Date.clone();
    const dateSecond = availabilities[availabilities.length - 1].Date.clone();

    const dateToday = dayjs().startOf("day");
    const dateTomorrow = dateToday.clone().add(1, "days");
    const dateTheDayAfterTomorrow = dateToday.clone().add(2, "days");

    this.labelDayFirst = i18next.t("label.today");

    if (dateFirst.isSame(dateTomorrow))
      this.labelDayFirst =
        i18next.t("label.tomorrow") + ` (${dateFirst.format("LL")})`;
    else if (dateFirst.isSame(dateTheDayAfterTomorrow))
      this.labelDayFirst =
        i18next.t("label.aftertomorrow") + ` (${dateFirst.format("dddd LL")})`;
    else if (!dateFirst.isSame(dateToday))
      this.labelDayFirst = dateFirst.format("dddd LL");

    if (dateSecond.isSame(dateTomorrow))
      this.labelDaySecond =
        i18next.t("label.tomorrow") + ` (${dateSecond.format("LL")})`;
    else if (dateSecond.isSame(dateTheDayAfterTomorrow))
      this.labelDaySecond =
        i18next.t("label.aftertomorrow") + ` (${dateSecond.format("dddd LL")})`;
    else if (!dateSecond.isSame(dateTomorrow))
      this.labelDaySecond = dateSecond.format("dddd LL");

    for (let availability of availabilities) {
      if (availability.Date.isSame(dateFirst)) {
        this.availabilitiesDayFirst = [
          ...this.availabilitiesDayFirst,
          availability
        ];
      }

      if (availability.Date.isSame(dateSecond)) {
        this.availabilitiesDaySecond = [
          ...this.availabilitiesDaySecond,
          availability
        ];
      }
    }
  }

  handleSwitchCalendar = () => {
    const calendarOpen = document
      .getElementById("chooseDate")
      .classList.contains("open");

    const timesOpen = document
      .getElementById("chooseTime")
      .classList.contains("open");

    if (calendarOpen)
      document.getElementById("chooseDate").classList.remove("open");
    else document.getElementById("chooseDate").classList.add("open");

    let dateCalendarMinimal;
    if (timesOpen && isValidArray(this.state.availabilities, 1))
      dateCalendarMinimal = this.state.availabilities[0].Date.clone();

    this.setState({
      calendarOpen: !calendarOpen,
      overrideMinimalDate: dateCalendarMinimal
    });
  };

  handleAppointmentTimeSelect = (availability, isWarning) => {
    if (!confirmOtherEmployee) {
      this.props.onSelect(availability);
      return;
    }

    if (!isWarning) {
      this.props.onSelect(availability);
      return;
    }

    this.setState({ availability, isOtherEmployee: true });
  };

  onSelect = () => {
    this.setState({ isOtherEmployee: false });
    this.props.onSelect(this.state.availability);
  };

  onCancel = () => {
    this.setState({ availability: null, isOtherEmployee: false });
  };

  onCalendarDaySelected = (selectedDate, employeeUnavailable) => {
    const { treatments, employee, language } = this.props;

    document.getElementById("chooseDate").classList.remove("open");
    document.getElementById("chooseTime").classList.add("open");

    this.setState({ labelDayCalendar: "", availabilitiesDayCalendar: null });

    appointmentService
      .fetchAvailabilitiesDay(
        treatments,
        employee,
        selectedDate,
        employeeUnavailable,
        language
      )
      .then(availabilities => {
        this.setState({
          availabilitiesDayCalendar: availabilities,
          labelDayCalendar: selectedDate.format("dddd LL"),
          calendarOpen: false
        });
        this.scrollTo();
      });
  };

  scrollTo = () => {
    if (this.calendarScroll && this.calendarScroll.current)
      this.calendarScroll.current.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
  };

  render() {
    const {
      currentStep,
      language,
      treatments,
      employee,
      availability,
      idAppointment,
      onChange,
      onSelect,
      onReturnToEmployees
    } = this.props;

    const {
      availabilities,
      availabilitiesDayCalendar,
      calendarOpen,
      isOtherEmployee
    } = this.state;

    this.showAvailabilities(treatments, employee, availabilities);

    return (
      <Section
        id="appointment"
        title={
          idAppointment ? "appointment-change.header" : "appointment.header"
        }
        step={Steps.Appointment}
        currentStep={currentStep}
        description={
          availability != null && currentStep !== Steps.Appointment
            ? availability.FullDateTime
            : ""
        }
        onChange={onChange}
      >
        <Popup
          open={isOtherEmployee}
          closeOnDocumentClick
          onClose={this.onCancel}
        >
          <div className="modal">
            <a className="close" onClick={this.onCancel}>
              &times;
            </a>
            <p style={{ textAlign: "center" }}>
              <Trans i18nKey="appointment.employee.unavailable.time.popup">
                Je hebt gekozen voor een 'rode' tijd. Een <b>andere</b> stylist
                zal daarom de behandeling uitvoeren.<br />
                <br />
                Druk op de volgende knop om hiermee akkoord te gaan.<br />
                <br />
              </Trans>
              <button
                onClick={this.onSelect}
                style={{ textTransform: "uppercase" }}
              >
                <Trans i18nKey="appointment.employee.unavailable.time.popup.confirm">
                  Akkoord
                </Trans>
              </button>
              <br />
              <br />
            </p>
          </div>
        </Popup>

        {employee && employee.DateFurloughUntill ? (
          <div className="message warning">
            <svg className="shape-icon-message-note">
              <use xlinkHref="#shape-icon-message-note" />
            </svg>{" "}
            <p>
              <Trans i18nKey="appointment.employee.furlough">
                {{
                  dateFurloughUntill: employee.DateFurloughUntill
                }}
              </Trans>{" "}
              <span role="link" onClick={() => onReturnToEmployees()}>
                <Trans i18nKey="appointment.employee.other">
                  Kies een andere stylist.
                </Trans>
              </span>
            </p>
          </div>
        ) : null}
        {!calendarOpen &&
          !availabilitiesDayCalendar && (
            <React.Fragment>
              <h3>{this.labelDayFirst}</h3>
              <AppointmentAvailabilities
                availabilities={this.availabilitiesDayFirst}
                employee={employee}
                onSelect={this.handleAppointmentTimeSelect}
              />
              {this.labelDayFirst !== this.labelDaySecond && (
                <React.Fragment>
                  <h3>{this.labelDaySecond}</h3>
                  <AppointmentAvailabilities
                    availabilities={this.availabilitiesDaySecond}
                    employee={employee}
                    onSelect={this.handleAppointmentTimeSelect}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        <div
          role="link"
          locale={language}
          className="datepicker"
          onClick={() => this.handleSwitchCalendar()}
        >
          <svg className="shape-icon-calendar">
            <use xlinkHref="#shape-icon-calendar" />
          </svg>
          <Trans i18nKey="appointment.date.other">Kies een andere dag</Trans>
        </div>
        <div id="chooseDate">
          {!calendarOpen &&
            this.availabilitiesDayFirst.IsError &&
            this.availabilitiesDaySecond.IsError && (
              <Warning>
                <Trans i18nKey="appointment.employee.unavailable.term">
                  Er is geen beschikbaarheid op de korte termijn. Kies een
                  andere dag.
                </Trans>
              </Warning>
            )}

          <AppointmentCalendar
            ref={this.appointmentCalendarControl}
            language={language}
            treatments={this.props.treatments}
            employee={this.props.employee}
            onSelect={onSelect}
            calendarOpen={calendarOpen}
            overrideMinimalDate={this.state.overrideMinimalDate}
            onCalendarDaySelected={this.onCalendarDaySelected}
          />
        </div>

        <div id="chooseTime">
          <h3>{this.state.labelDayCalendar}</h3>
          <AppointmentAvailabilities
            availabilities={availabilitiesDayCalendar}
            employee={employee}
            onSelect={this.handleAppointmentTimeSelect}
          />
        </div>
        <div ref={this.calendarScroll} />
      </Section>
    );
  }
}

//export default withTranslation(null, { withRef: true })(Appointment);
export default Appointment;

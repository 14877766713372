export const titles = [
  {
    Indicator: "-1",
    Description: "form.title.choose"
  },
  {
    Indicator: "M",
    Description: "form.title.mister"
  },
  {
    Indicator: "F",
    Description: "form.title.madam"
  },
  {
    Indicator: "O",
    Description: "form.title.different"
  }
];

export default titles;

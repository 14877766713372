import React from "react";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import i18next from "i18next";
import dayjs from "dayjs";
import Joi from "joi";
import Warning from "../appointments/warning";
import Form from "../common/form";
import subscriptionService from "../../services/subscriptionService";
import { formatPrice, fromISO } from "../common/check";

class RegisterSubscriptionForm extends Form {
  state = {
    data: {
      email: "",
      iban: "",
      accountName: ""
    },
    errors: {}
  };

  schema = Joi.object({
    email: Joi.string()
      .trim()
      .required()
      .email({ minDomainSegments: 2, tlds: false })
      .label("label.email"),
    iban: Joi.string()
      .required()
      .label("label.bank-account.number"),
    accountName: Joi.string()
      .required()
      .label("label.bank-account.name")
  });

  async componentDidMount() {
    const { data } = this.state;
    const { customer } = this.props;

    data.email = customer.Email;
    data.accountName =
      customer.Initials.toUpperCase() + ". " + customer.LastName.toUpperCase();

    this.setState({ data });
  }

  doChange = async input => {
    const { errors } = this.state;

    this.fetchIban(input, errors);
  };

  doSubmit = async () => {
    const { data } = this.state;

    const isValidIban = await subscriptionService.validateIban(
      data.iban.trim()
    );

    if (!isValidIban) toast.error(i18next.t("error.bank-account.message"));

    await this.handleCreateSubscription();
  };

  handleCreateSubscription = async () => {
    const { data } = this.state;
    const { customer, subscription } = this.props;
    const queryString = `?idCustomer=${customer.Id}&idSubscription=${
      subscription.Id
    }`;

    const result = await subscriptionService.create(
      customer,
      subscription,
      data.iban,
      data.accountName,
      window.location.href.replace("abonnementen", "") +
        "abonnement-betaalstatus" +
        queryString
    );

    if (result && !result.IsError) window.location = result;
  };

  fetchIban = async (input, errors) => {
    if (!(input && input.name === "iban")) return;

    if (errors["iban"]) return;

    if (input.value.trim().length < 22) return;

    const isValidIban = await subscriptionService.validateIban(
      input.value.trim()
    );
    if (isValidIban) {
      this.setState({ isValidIban });
      return;
    }

    errors["iban"] = i18next.t("error.bank-account");
    toast.error(i18next.t("error.bank-account.message"));

    this.setState({ errors });
  };

  render() {
    const { isValidIban } = this.state;
    const { subscription, onCreateSubscription, language } = this.props;

    dayjs.locale(language);

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="fr">
          {this.renderInputNative(
            "email",
            "label.email.validate",
            10,
            true,
            "email",
            "full"
          )}
          <div className="message info">
            <svg className="shape-icon-message-info">
              <use xlinkHref="#shape-icon-message-info" />
            </svg>{" "}
            <Trans i18nKey="label.email.remark.subscription">
              Wij gebruiken dit e-mail adres voor meldingen over de incasso's.
            </Trans>
          </div>
        </div>
        <div className="fr">
          {this.renderInput("iban", "label.bank-account.number", {
            useDiv: false,
            showLabel: false,
            upperCase: true,
            type: "iban",
            className: "full"
          })}
          {isValidIban && (
            <svg className="shape-icon-check" width="14" height="12">
              <use xlinkHref="#shape-icon-check" />
            </svg>
          )}
        </div>
        {this.renderInput("accountName", "label.bank-account.name", {
          showLabel: true,
          className: "full"
        })}
        {this.renderCheckBoxAutomaticCollect()}
        {this.renderCheckBoxSubscription()}
        {this.renderCheckBoxDisclaimerPrivacy()}
        <Trans i18nKey="subscriptions.summary">
          <p>
            {{
              price: formatPrice(subscription.PriceStartNow)
            }},
            {{
              dateFrom: fromISO(subscription.DateCollection).format("LL")
            }}
          </p>
        </Trans>
        <Warning>
          <Trans i18nKey="subscriptions.payment">
            Let op! De betaling van jouw abonnement verloopt via onze
            incassopartner Flexxis B.V.<br />Je wordt omgeleid naar de
            betaalpagina van Mollie waar je een veilige transactie kunt
            uitvoeren.
          </Trans>
        </Warning>
        {this.renderButton("label.subscription.subscribe", false, true)}
        {"  "}
        <span
          role="link"
          className="cancel"
          onClick={() => onCreateSubscription(null)}
        >
          <Trans i18nKey="label.cancel" />
        </span>
      </form>
    );
  }
}

export default RegisterSubscriptionForm;

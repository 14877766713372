import React from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";
import Joi from "joi";
import Form from "../common/form";
import customerService from "../../services/customerService";
import RegisterForgotPasswordForm from "./register-forgot-password";

class LoginForm extends Form {
  state = {
    forgotPassword: false,
    data: { email: "", password: "", rememberme: true },
    errors: {}
  };

  schema = Joi.object({
    email: Joi.string()
      .trim()
      .required()
      .email({ minDomainSegments: 2, tlds: false })
      .label("label.email"),
    password: Joi.string()
      .required()
      .label("label.password"),
    rememberme: Joi.boolean()
  });

  constructor(props) {
    super(props);

    this.buttonLogin = React.createRef();
  }

  onForgotPasswordClick = () => {
    this.setState({ forgotPassword: true });
  };

  handleCancel = () => {
    this.setState({ forgotPassword: false });
  };

  handleLogin = customer => {
    const { onLogin, navigation } = this.props;

    if (!customer) {
      const errors = { ...this.state.errors };
      errors.password = i18next.t("label.password.invalid");
      this.setState({ errors });

      if (this.buttonLogin.current)
        this.buttonLogin.current.removeAttribute("disabled");
      return;
    }

    if (this.buttonLogin.current)
      this.buttonLogin.current.removeAttribute("disabled");

    if (onLogin) onLogin(customer, navigation);
  };

  doChange = async input => {};

  doSubmit = () => {
    const { data } = this.state;
    const { language, countries } = this.props;

    if (this.buttonLogin.current)
      this.buttonLogin.current.setAttribute("disabled", "disabled");

    try {
      customerService
        .login(data, language, countries)
        .then(customer => this.handleLogin(customer));
    } catch (exception) {
      if (exception.response && exception.response.status === 204) {
        const errors = { ...this.state.errors };
        errors.email = exception.response.data;
        this.setState({ errors });

        if (this.buttonLogin.current)
          this.buttonLogin.current.removeAttribute("disabled");
        return;
      }
    }
    /*
      if (this.buttonLogin.current)
        this.buttonLogin.current.removeAttribute("disabled");
  
      if (onLogin) onLogin(customer, navigation);
      /**/
  };

  render() {
    const { forgotPassword } = this.state;

    if (forgotPassword)
      return <RegisterForgotPasswordForm onCancel={this.handleCancel} />;
    else
      return (
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("email", "label.email", {
            type: "email",
            showLabel: false,
            className: "email",
            lowerCase: true
          })}
          {this.renderInput("password", "label.password", {
            showLabel: false,
            className: "password",
            type: "password"
          })}
          {this.renderCheckBox("rememberme", "label.rememberme", true)}
          <div className="fr">
            {this.renderButton("label.login", true, false, this.buttonLogin)}
          </div>
          <div role="link" onClick={() => this.onForgotPasswordClick()}>
            <Trans i18nKey="label.password.forgot">Wachtwoord vergeten?</Trans>
          </div>
        </form>
      );
  }
}

export default LoginForm;

export const hairstyles = [
  {
    Indicator: "O",
    IndicatorGender: "O",
    Description: "label.gender.different"
  },
  {
    Indicator: "M",
    IndicatorGender: "M",
    Description: "label.gender.male"
  },
  {
    Indicator: "F",
    IndicatorGender: "F",
    Description: "label.gender.female"
  },
  {
    Indicator: "FS",
    IndicatorGender: "F",
    Description: "label.gender.female.hairlength.short"
  },
  {
    Indicator: "FM",
    IndicatorGender: "F",
    Description: "label.gender.female.hairlength.medium"
  },
  {
    Indicator: "FL",
    IndicatorGender: "F",
    Description: "label.gender.female.hairlength.long"
  }
];

export default hairstyles;

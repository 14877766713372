import React from "react";

const Warning = props => {
  return (
    <div className="message warning">
      <svg className="shape-icon-message-note">
        <use xlinkHref="#shape-icon-message-note" />
      </svg>{" "}
      {props.custom ? (
        props.children
      ) : (
        <p style={{ textAlign: "center" }}>{props.children}</p>
      )}
    </div>
  );
};

export default Warning;

import http from "./httpService";

const { subscription } = window.env;

const { apiUrl, license, debug } = window.env;
const apiEndpoint = !debug
  ? apiUrl + "/subscription/" + license + "/"
    : "https://localhost:44357/SubscriptionService.svc/" + license + "/";

export async function fetchSubscriptions() {
  const queryString = `?loadPrice=true`;

  const { IsError: isError, Value: subscriptions } = await http.get(
    apiEndpoint + "FetchSubscriptions" + queryString
  );
  if (isError) return [];

  return subscriptions;
}

export async function fetchCustomerSubscriptions(customer) {
  if (!customer || !subscription) return [];

  const queryString = `?idCustomer=${customer.Id}`;

  const { IsError: isError, Value: customerSubscriptions } = await http.get(
    apiEndpoint + "FetchCustomerSubscriptions" + queryString
  );
  if (isError) return [];

  return customerSubscriptions;
}

export async function create(
  customer,
  subscription,
  iban,
  accountName,
  redirectUrl
) {
  if (!customer || !subscription) return null;

  const { IsError: isError, Value: status } = await http.post(
    apiEndpoint + "Create",
    {
      idCustomer: customer.Id,
      idSubscription: subscription.Id,
      iban,
      accountName,
      redirectUrl
    }
  );

  if (isError) return null;

  return status;
}

export async function cancel(customer, subscription) {
  if (!customer || !subscription) return null;

  const { IsError: isError, Value: dateCancelled } = await http.post(
    apiEndpoint + "Cancel",
    {
      idCustomer: customer.Id,
      idSubscription: subscription.Id
    }
  );

  if (isError) return null;

  return dateCancelled;
}

export async function validateIban(iban) {
  if (!iban) return null;

  const { IsError: isError, Value: success } = await http.post(
    apiEndpoint + "ValidateIban",
    {
      iban
    }
  );

  if (isError) return null;

  return success;
}

export async function isPaid(idCustomer, idSubscription) {
  if (!idCustomer || !idSubscription) return null;

  const { IsError: isError } = await http.post(apiEndpoint + "IsPaidUntill", {
    idCustomer,
    idSubscription
  });

  if (isError) return null;

  return "paid";
}

export default {
  fetchSubscriptions,
  fetchCustomerSubscriptions,
  create,
  cancel,
  validateIban,
  isPaid
};

import React, { Component } from "react";
import { Trans } from "react-i18next";
import appointmentService from "../../services/appointmentService";
import queryString from "query-string";
import { fromISO } from "../common/check";

class Cancel extends Component {
    state = {};

    componentDidMount() {
        if (this.state.resultCancel)
            return;

        const href = window.location && window.location.href ? window.location.href : "";

        if (href.includes("?")) {
            const params = queryString.parse(window.location.search);
            const idAppointment = Object.keys(params)[0];

            appointmentService.fetchFutureAppointment(idAppointment).then(result => { this.setState({ idAppointment, resultFetch: result }) });
        }
    }

    onCancelAppointment() {
        const { idAppointment } = this.state;
        appointmentService.cancelAppointment(idAppointment).then(result => { this.setState({ resultCancel: result }); });
    }

    render() {
        const { resultFetch, resultCancel } = this.state;
        let cancelAllowed = true;
        let date = null;
        let isDeleted = false;

        if (resultFetch) {
            cancelAllowed = resultFetch.IsCancellationAllowed;
            date = fromISO(resultFetch.DateISO).format("LL");
            isDeleted = resultFetch.IsDeleted;
        }

        const showCancel = cancelAllowed && !resultCancel && !isDeleted;
        const deniedCancel = !cancelAllowed || (resultCancel && resultCancel.IsError);
        const isCancelled = isDeleted || (resultCancel && !resultCancel.IsError);

        return (
            <section className="account">
                {showCancel && (
                    <header>
                        <Trans i18nKey="appointment-cancel.header">
                            <h1>Afspraak annuleren</h1>
                        </Trans>
                        <Trans i18nKey="appointment-cancel.confirm">
                            <p>Weet je zeker dat je de afspraak van {{date}} wilt annuleren?</p>
                        </Trans>
                        <span role="link" onClick={() => window.location = "/"}>
                            <Trans i18nKey="label.no">
                                Nee
                            </Trans>
                        </span>&nbsp;/&nbsp;
                        <span role="link" onClick={() => this.onCancelAppointment()}>
                            <Trans i18nKey="label.yes">
                                Ja
                            </Trans>
                        </span>
                    </header>
                )}
                {deniedCancel && (
                    <header>
                        <Trans i18nKey="appointment-cancel.header.failed">
                            <h1>Niet toegestaan</h1>
                        </Trans>
                        <Trans i18nKey="appointment-cancel.failed">
                            <p>Deze afspraak is al heel binnenkort. Het is daarom helaas niet meer mogelijk deze afspraak te annuleren.</p>
                        </Trans>
                        <div role="link" onClick={() => window.location = "/"}>
                            <Trans i18nKey="navigation.gobacktostart">
                                Terug naar beginpagina
                            </Trans>
                        </div>
                    </header>
                )}
                {isCancelled && (
                    <header>
                        <Trans i18nKey="appointment-cancel.header.success">
                            <h1>Afspraak geannuleerd</h1>
                        </Trans>
                        <Trans i18nKey="appointment-cancel.success">
                            <p>Deze afspraak is (al) geannuleerd.</p>
                        </Trans>
                        <div role="link" onClick={() => window.location = "/"}>
                            <Trans i18nKey="label.appointment.new.click">
                                Klik hier om een nieuwe afspraak te maken
                            </Trans>
                        </div>
                    </header>
                )}
            </section>
        );
    }
}

export default Cancel;

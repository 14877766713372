import React, { Component } from "react";
import { Trans } from "react-i18next";
import queryString from "query-string";
import appointmentService from "../services/appointmentService";
import giftcardService from "../services/giftcardService";
import Loader from "./appointments/loader";
/*import Warning from "./appointments/warning";*/

const { redirectAfterAppointment } = window.env;

class PaymentStatus extends Component {
    state = { paymentStatus: "pending" };

    async componentDidMount() {
        this.fetchPaymentStatus();
    }

    async componentDidUpdate() {
        const { paymentStatus, idAppointment, method } = this.state;

        if (idAppointment && method === "deposit" && paymentStatus !== "pending" && paymentStatus !== "paid") {
            window.location.replace("/mislukt?" + idAppointment);
            return;
        }

        if (!paymentStatus || paymentStatus === "pending") {
            setTimeout(() => {
                this.fetchPaymentStatus();
            }, 3000);
        }

        if (redirectAfterAppointment && idAppointment && paymentStatus === "paid") {
            setTimeout(() => {
                window.location.replace(redirectAfterAppointment);
            }, 5000);
        }
    }

    fetchPaymentStatus = () => {
        const params = queryString.parse(window.location.search);
        const { idAppointment, idGiftcard, method } = params;

        if (idAppointment) {
            appointmentService
                .fetchPaymentStatus(idAppointment)
                .then(result => this.setState({ paymentStatus: result, idAppointment, method }));
        }

        if (idGiftcard)
            giftcardService
                .fetchPaymentStatus(idGiftcard)
                .then(result => this.setState({ paymentStatus: result, idGiftcard }));
    };

    render() {
        const { paymentStatus } = this.state;

        if (!paymentStatus || paymentStatus === "pending")
            return (
                <section className="account">
                    <header>
                        <Trans i18nKey="payment.header.pending">
                            <h1>Wachten op antwoord van de betaalinstantie</h1>
                        </Trans>
                    </header>
                    <Loader />
                    <Trans i18nKey="payment.description.pending">
                        <div>
                            <Trans i18nKey="payment.description.pending" />
                        </div>
                    </Trans>
                </section>
            );
        else if (paymentStatus && paymentStatus !== "paid")
            return (
                <section className="account">
                    <header>
                        <Trans i18nKey="payment.header.failed">
                            <h1>Betaling MISLUKT!</h1>
                        </Trans>
                    </header>
                    <div>
                        <Trans i18nKey="payment.description.failed" />
                    </div>
                </section>
            );
        else
            return (
                <section className="account">
                    <header>
                        <Trans i18nKey="payment.header.success">
                            <h1>Betaling gelukt</h1>
                        </Trans>
                    </header>
                    <div>
                        <Trans i18nKey="payment.description.success" />
                    </div>
                </section>
            );
    }
}

export default PaymentStatus;

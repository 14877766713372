import React, { Component } from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";

class Contact extends Component {
  render() {
    document.title = i18next
      .t("navigation.contact")
      .replace("<0>", "")
      .replace("</0>", "");

    const { branches } = this.props;

    if (!branches) return <section className="account" />;
    else
      return (
        <section className="account">
          {branches.map(branch => (
            <React.Fragment key={branch.Id}>
              <header>
                <h1>
                  {" "}
                  <Trans i18nKey="navigation.contact">
                    <strong>Over</strong>
                  </Trans>
                  {" " + branch.Name}
                </h1>
              </header>
              <p>
                {branch.Address}
                <br />
                {branch.Zip}
                {"  "}
                {branch.City}
                <br />
                t: {branch.Telephone}
                <br />
                <strong>e:</strong>{" "}
                <a href={"mailto:" + branch.Email}>{branch.Email}</a>
              </p>
            </React.Fragment>
          ))}
        </section>
      );
  }
}

export default Contact;

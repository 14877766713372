import React from "react";
import Loader from "./loader";
import Warning from "./warning";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { EmptyKey } from "../../services/constants";

const AppointmentAvailabilities = ({ availabilities, employee, onSelect }) => {
  if (
    !availabilities ||
    (!availabilities.IsError && availabilities.length === 0)
  )
    return <Loader />;

  if (availabilities && availabilities.IsError)
    return (
      <Warning>
        <Trans i18nKey="appointment.availability.error">
          Er is geen beschikbaarheid op deze dag. Kies een andere dag.
        </Trans>
      </Warning>
    );

  let availabilitiesFilter = [];
  for (let availability of availabilities) {
    if (
      availability.IsPreferred &&
      !availabilitiesFilter.find(
        f =>
          f.TimeValue > availability.TimeValue - 30 &&
          f.TimeValue <= availability.TimeValue
      )
    )
      availabilitiesFilter = availabilitiesFilter.concat(availability);
  }

  for (let availability of availabilities) {
    if (
      !availabilitiesFilter.find(
        f =>
          f.TimeValue > availability.TimeValue - 30 &&
          f.TimeValue <= availability.TimeValue
      )
    )
      availabilitiesFilter = availabilitiesFilter.concat(availability);
  }

  availabilities = availabilitiesFilter.sort(
    (a, b) => a.TimeValue - b.TimeValue
  );

  const isWarning =
    availabilities &&
    !availabilities.IsError &&
    availabilities.find(f => f.IsPreferred === false);

  return (
    <ul>
      {availabilities.map(availability => (
        <li
          key={availability.DateTime}
          className={!availability.IsPreferred ? "warning" : ""}
          title={
            !availability.IsPreferred && (employee && employee.Id !== EmptyKey)
              ? i18next.t("appointment.employee.random")
              : null
          }
        >
              <button onClick={() => onSelect(availability, !availability.IsPreferred && (employee && employee.Id !== EmptyKey))}>
            {availability.Time}
          </button>
        </li>
      ))}
      {isWarning && (
        <Warning>
          <Trans i18nKey="appointment.employee.unavailable.time">
            {{
              employeeFirstName: employee ? employee.FirstName : "Stylist"
            }}{" "}
          </Trans>
        </Warning>
      )}
    </ul>
  );
};

export default AppointmentAvailabilities;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Trans } from "react-i18next";

class Navigation extends Component {
  state = {
    languages: null
  };

  componentDidMount() {
    // Open/close menu
    document.getElementById("open-menu").addEventListener("click", function() {
      document.documentElement.classList.add("menu-opened");
    });

    this.addClose("close-menu");
    this.addClose("navigation.appointment");
    this.addClose("navigation.about");
    this.addClose("navigation.contact");
    this.addClose("navigation.myappointments");
    this.addClose("navigation.account");
    this.addClose("navigation.subscriptions");
    this.addClose("navigation.logout");
    this.addClose("navigation.register");
    this.addClose("navigation.login");

    const href =
      window.location && window.location.href ? window.location.href : "";

    const changeAppointment = href.includes("afspraak-wijzigen?");

    let { languages } = window.env;
    if (!languages) languages = ["nl", "en", "de"];
    this.setState({ languages, changeAppointment });

    for (let language of languages) this.addClose(language);
  }

  addClose(id) {
    const element = document.getElementById(id);
    if (!element) return;

    element.addEventListener("click", function() {
      document.documentElement.classList.toggle("menu-opened");
    });
  }

  render() {
    const { customer, language, onLanguageChange } = this.props;
    const { clientWebsite, subscription, giftcard } = window.env;
    const { languages, changeAppointment } = this.state;

    return (
      <header id="header">
        <ToastContainer />
        <h1>
          <Trans
            i18nKey={changeAppointment ? "Afspraak wijzigen" : "Afspraak maken"}
          />
        </h1>
        <nav>
          <div id="logo">
            <a href={clientWebsite}>
              <Trans i18nKey="navigation.goback">
                <span>Ga terug</span>
              </Trans>
            </a>
          </div>
          <a href={clientWebsite} id="back">
            <svg className="shape-icon-arrow left">
              <use xlinkHref="#shape-icon-arrow" />
            </svg>
            <Trans i18nKey="navigation.gobacktowebsite">
              Terug <span>naar de website</span>
            </Trans>
          </a>
          <button id="open-menu">
            <svg className="shape-icon-menu">
              <use xlinkHref="#shape-icon-menu" />
            </svg>
          </button>
          <div id="menu">
            <button id="close-menu">
              <svg className="shape-icon-close">
                <use xlinkHref="#shape-icon-close" />
              </svg>
            </button>
            <ul>
              <li>
                <Trans i18nKey="navigation.appointment">
                  <Link id="navigation.appointment" to="/">
                    Afspraak
                  </Link>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="navigation.about">
                  <Link id="navigation.about" to="/over">
                    Over
                  </Link>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="navigation.contact">
                  <Link id="navigation.contact" to="/contact">
                    Contact
                  </Link>
                </Trans>
              </li>
              {giftcard && (
                <li>
                  <Trans i18nKey="navigation.giftcard">
                    <Link id="navigation.giftcard" to="/giftcard">
                      Cadeaubon
                    </Link>
                  </Trans>
                </li>
              )}

              {customer && (
                <React.Fragment>
                  <li>
                    <Trans i18nKey="navigation.myappointments">
                      <Link id="navigation.myappointments" to="/afspraken">
                        Mijn afspraken
                      </Link>
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="navigation.myaccount">
                      <Link id="navigation.account" to="/account">
                        Mijn account
                      </Link>
                    </Trans>
                  </li>
                  {subscription && (
                    <li>
                      <Trans i18nKey="navigation.subscriptions">
                        <Link id="navigation.subscriptions" to="/abonnementen">
                          Abonnementen
                        </Link>
                      </Trans>
                    </li>
                  )}
                  <li>
                    <Trans i18nKey="navigation.logout">
                      <Link id="navigation.logout" to="/uitloggen">
                        Uitloggen
                      </Link>
                    </Trans>
                  </li>
                </React.Fragment>
              )}

              {!customer && (
                <React.Fragment>
                  {subscription && (
                    <li>
                      <Trans i18nKey="navigation.subscriptions">
                        <Link id="navigation.subscriptions" to="/abonnementen">
                          Abonnementen
                        </Link>
                      </Trans>
                    </li>
                  )}
                  <li>
                    <Trans i18nKey="navigation.register">
                      <Link id="navigation.register" to="/registreer">
                        Registreer
                      </Link>
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="navigation.login">
                      <Link id="navigation.login" to="/inloggen">
                        Inloggen
                      </Link>
                    </Trans>
                  </li>
                </React.Fragment>
              )}
            </ul>
            {true && (
              <ul id="languages">
                {languages &&
                  languages.map(lang => (
                    <li
                      lang={lang}
                      key={lang}
                      className={lang === language ? "active" : "false"}
                    >
                      <span
                        id={lang}
                        role="link"
                        title={lang}
                        onClick={() => onLanguageChange(lang)}
                      >
                        <svg className={"shape-icon-language-" + lang}>
                          <use xlinkHref={"#shape-icon-language-" + lang} />
                        </svg>
                      </span>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </nav>
      </header>
    );
  }
}

export default Navigation;

import { Navigate } from "react-router-dom";
import customerService from "../../services/customerService";

const ProtectedRoute = ({ path, element: Element, render, ...rest }) => {
    const authorized = customerService.getCurrentCustomer();

    return authorized ? <Element {...rest} /> : <Navigate to="/inloggen" />;
};

export default ProtectedRoute;

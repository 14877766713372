import React, { Component } from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";

class About extends Component {
  state = {};

  createMarkup() {
    const { branchParameters } = this.props;

    return { __html: branchParameters.OnlineAboutText };
  }

  render() {
      document.title = i18next.t("navigation.about")
      .replace("<0>", "")
      .replace("</0>", "");

    const { branchParameters, branches } = this.props;

    if (!branchParameters) return <section className="account" />;
    else {
      return (
        <section className="account">
          <header>
            <h1>
              <Trans i18nKey="navigation.about">
                <strong>Over</strong>
              </Trans>
              {(branches === null || branches.length === 1) &&
                " " + branchParameters.Name}
            </h1>
          </header>
          <div dangerouslySetInnerHTML={this.createMarkup()} />
        </section>
      );
    }
  }
}

export default About;

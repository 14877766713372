import React, { Component } from "react";
import { Trans } from "react-i18next";
import queryString from "query-string";
import subscriptionService from "../services/subscriptionService";
import Loader from "./appointments/loader";

class SubscriptionPaymentStatus extends Component {
  state = { paymentStatus: "pending", redirect: false };

  constructor(props) {
    super(props);
    this.retries = 0;
  }

  async componentDidMount() {
    this.fetchPaidUntill();
  }

  async componentDidUpdate() {
    let { paymentStatus } = this.state;

    if (this.retries > 15) {
      paymentStatus = "timeout";
      this.setState({ paymentStatus });
    }

    if (!paymentStatus || paymentStatus === "pending") {
      setTimeout(() => {
        this.fetchPaidUntill();
      }, 3000);

      this.retries = this.retries + 1;
    }

    if (paymentStatus === "paid")
      setTimeout(() => (window.location = "/abonnementen"), 3000);
  }

  fetchPaidUntill = () => {
    const params = queryString.parse(window.location.search);
    const idCustomer = params.idCustomer;
    const idSubscription = params.idSubscription;

    subscriptionService
      .isPaid(idCustomer, idSubscription)
      .then(result => this.setState({ paymentStatus: result }));
  };

  render() {
    const { paymentStatus } = this.state;

    if (!paymentStatus || paymentStatus === "pending")
      return (
        <section className="account">
          <header>
            <Trans i18nKey="payment.header.pending">
              <h1>Wachten op antwoord van de betaalinstantie</h1>
            </Trans>
          </header>
          <Loader />
          <div>
            <Trans i18nKey="payment.description.pending" />
          </div>
        </section>
      );
    else if (paymentStatus && paymentStatus !== "paid")
      return (
        <section className="account">
          <header>
            <Trans i18nKey="payment.header.failed">
              <h1>Betaling MISLUKT!</h1>
            </Trans>
          </header>

          <div>
            <Trans i18nKey="payment.description.failed" />
          </div>
        </section>
      );
    else
      return (
        <section className="account">
          <header>
            <Trans i18nKey="payment.header.success">
              <h1>Betaling gelukt</h1>
            </Trans>
          </header>

          <div>
            <Trans i18nKey="payment.description.success.subscription" />
          </div>
        </section>
      );
  }
}

export default SubscriptionPaymentStatus;

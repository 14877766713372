import * as Sentry from "@sentry/react";
import config from "../config.json";

const { debug } = window.env;

function init() {
  Sentry.init({
    dsn:
      "https://d3b790652a5040868ab410a33049156f@o278743.ingest.sentry.io/1501449",
    release: config.release,
    environment: config.environment,
    ignoreErrors: [
      "Object Not Found Matching Id"
    ]
  });
}

function log(error) {
  if (debug) return;

  Sentry.captureException(error);
}

export function throwError(errorMessage, noContent) {
  const error = new Error(errorMessage);
  log(error);

  error.IsError = true;
  error.Message = error.message;
  error.NoContent = noContent;
  return error;
}

export default {
  init,
  log,
  throwError
};

const { treatmentGroupOrder } = window.env;

export const treatmentGroups = [
  {
    key: "0",
    order: 0,
    category: "Abonnementen",
    treatments: [],
    style: "shape-icon-subscriptions",
    shape: "#shape-icon-subscriptions"
  },
  {
    key: "1",
    order: treatmentGroupOrder ? treatmentGroupOrder[0] : 1,
    category: "Knippen",
    treatments: [],
    style: "shape-icon-treatment-cut",
    shape: "#shape-icon-treatment-cut"
  },
  {
    key: "2",
    order: treatmentGroupOrder ? treatmentGroupOrder[1] : 2,
    category: "Kleuren",
    treatments: [],
    style: "shape-icon-treatment-color",
    shape: "#shape-icon-treatment-color"
  },
  {
    key: "3",
    order: treatmentGroupOrder ? treatmentGroupOrder[2] : 3,
    category: "Styling",
    treatments: [],
    style: "shape-icon-treatment-care",
    shape: "#shape-icon-treatment-care"
  },
  {
    key: "4",
    order: treatmentGroupOrder ? treatmentGroupOrder[3] : 4,
    category: "Schoonheid",
    treatments: [],
    style: "shape-icon-treatment-beauty",
    shape: "#shape-icon-treatment-beauty"
  },
  {
    key: "5",
    order: treatmentGroupOrder ? treatmentGroupOrder[4] : 5,
    category: "Specials",
    treatments: [],
    style: "shape-icon-treatment-specials",
    shape: "#shape-icon-treatment-specials"
  }
];

export default treatmentGroups;

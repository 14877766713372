import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { getIcon } from "../../services/giftcardService";

class GiftcardCta extends Component {
  state = {};
  render() {
      const icon = getIcon();

    return (
      <section id="giftcardCta" className="themeBGColor">
        <div className="body">
                {icon && (
                    <svg className={icon}>
                        <use xlinkHref={"#" + icon} />
                    </svg>
                )}
          <svg className="shape-icon-giftcard">
            <use xlinkHref="#shape-icon-giftcard" />
          </svg>
          <div>
            <Trans i18nKey="giftcard.header">
              <h2>Cadeaubon vanaf € 5,–</h2>
            </Trans>
            <Trans i18nKey="giftcard.cta">
              <p>
                Steun je kapper en verwen iemand (of jezelf!) met een cadeaubon!
              </p>
            </Trans>
          </div>
          <button className="themeColor">
            <Trans i18nKey="giftcard.order-now">
              <Link to="/giftcard" className="themeColor">
                Bestel nu!
              </Link>
            </Trans>
          </button>
        </div>
      </section>
    );
  }
}

export default GiftcardCta;

import React, { Component } from "react";
import { Trans } from "react-i18next";
import Section, { Steps } from "./section";
import RegisterTempForm from "../forms/register-temp";
import RegisterClientForm from "../forms/register-client";

class RegisterTempNew extends Component {
  state = {
    newCustomerChecked: false
  };

  toggleNewCustomerChecked = () => {
    this.setState({ newCustomerChecked: !this.state.newCustomerChecked });
  };

  render() {
    const {
      currentStep,
      countries,
      customerNew,
      amountDeposit,
      onChange,
      onConfirm
    } = this.props;

    const { newCustomerChecked } = this.state;
    const hidden = !customerNew;
    const title = newCustomerChecked
      ? "register-customer.header"
      : "appointment.register-customer.header";

    return (
      <Section
        id="register-temp-new"
        title={title}
        step={Steps.Register}
        currentStep={currentStep}
        hidden={hidden}
        onChange={onChange}
      >
        {hidden ? null : (
          <React.Fragment>
            <div id="register-forms">
              <br />
              <div className="fr">
                <label>
                  <input
                    type="checkbox"
                    value={this.state.newCustomerChecked}
                    onChange={this.toggleNewCustomerChecked}
                  />
                  <Trans i18nKey="appointment.register-customer.checkbox">
                    Registreer als nieuwe klant
                  </Trans>
                </label>
                {newCustomerChecked ? (
                  <RegisterClientForm onConfirm={onConfirm} />
                ) : (
                  <RegisterTempForm
                    countries={countries}
                    amountDeposit={amountDeposit}
                    onConfirm={onConfirm}
                  />
                )}
              </div>
            </div>
            <aside>
              <Trans i18nKey="appointment.register-customer.advantages">
                <h3>Voordelen van registreren als klant</h3>
              </Trans>
              <Trans i18nKey="appointment.register-customer.summary">
                <p>
                  Het is niet verplicht om te registreren, maar een account
                  biedt echter wel voordelen:
                </p>
              </Trans>
              <ul>
                <Trans i18nKey="appointment.new-customer.why-register.list">
                  <li>sneller afspraken maken in de toekomst</li>
                  <li>inzicht in je afspraakgeschiedenis</li>
                  <li>op de hoogte blijven via optionele nieuwsbrief</li>
                </Trans>
              </ul>
            </aside>
          </React.Fragment>
        )}
      </Section>
    );
  }
}

export default RegisterTempNew;

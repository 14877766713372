import http from "./httpService";
import i18next from "i18next";
import dayjs from "dayjs";
import { fromISO } from "../components/common/check";
import { generateTreatmentList } from "./appointmentService";
import { EmptyKey } from "./constants";

const { apiUrl, license, debug, noPreferenceFirst } = window.env;

function apiEndpoint() {
  return !debug
    ? apiUrl + "/employee/" + (window.branch ? window.branch : license) + "/"
      : "https://localhost:44357/EmployeeService.svc/" +
        (window.branch ? window.branch : license) +
        "/";
}

export async function fetchEmployees(treatments, customer, language) {
  const treatmentList = generateTreatmentList(treatments);
  if (treatmentList.length === 0) return;

  dayjs.locale(language);

  let queryString = "";

  if (treatmentList.length === 1)
    queryString += `?idTreatment=${treatmentList[0]}`;
  else queryString += `?treatments=${treatmentList}`;

  if (customer) queryString = queryString + `&idCustomer=${customer.Id}`;

  let { IsError: isError, Value: employees } = await http.get(
    apiEndpoint() + "FetchEmployees" + queryString
  );

  if (isError) return null;

  for (let employee of employees) {
    if (employee.Id === EmptyKey)
      employee.FirstName = i18next.t("employee.no-preference");

    if (employee.DateFurloughUntill) {
      employee.DateFurloughUntill = fromISO(employee.DateFurloughUntill).format(
        "D MMMM"
      );
    }
  }

  if (noPreferenceFirst)
    employees = employees.sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1));

  return employees;
}

export default {
  fetchEmployees
};

import React, { Component } from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";
import RegisterClientForm from "./forms/register-client";

class Register extends Component {
  state = {};
  render() {
    document.title = i18next
      .t("register-customer.header")
      .replace("<0>", "")
      .replace("</0>", "");

    return (
      <section id="register" className="account">
        <header>
          <Trans i18nKey="register-customer.header">
            <h1>Registreer als klant</h1>
          </Trans>
        </header>
        <div className="body">
          <RegisterClientForm countries={this.props.countries} />
          <aside>
            <Trans i18nKey="appointment.register-customer.advantages">
              <h3>Voordelen van registreren als klant</h3>
            </Trans>
            <Trans i18nKey="appointment.register-customer.summary">
              <p>
                Het is niet verplicht om te registreren, maar een account biedt
                echter wel voordelen:
              </p>
            </Trans>
            <ul>
              <Trans i18nKey="appointment.new-customer.why-register.list">
                <li>sneller afspraken maken in de toekomst</li>
                <li>inzicht in je afspraakgeschiedenis</li>
                <li>op de hoogte blijven via optionele nieuwsbrief</li>
              </Trans>
            </ul>
          </aside>
        </div>
      </section>
    );
  }
}

export default Register;

import React, { Component } from "react";
import { Trans } from "react-i18next";

class NotFound extends Component {
    render() {
        return (
            <section className="account">
                <header>
                    <h1>Niet gevonden</h1>
                    <p>De pagina is niet gevonden.</p>
                    <div role="link" onClick={() => window.location = "/"}>
                        <Trans i18nKey="navigation.gobacktostart">
                            Terug naar beginpagina
                        </Trans>

                    </div>
                </header>
            </section>
        );
    }
}

export default NotFound;

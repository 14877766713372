import React from "react";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import i18next from "i18next";
import Joi from "joi";
import Form from "../common/form";
import customerService from "../../services/customerService";

class RegisterForgotPasswordForm extends Form {
  state = {
    data: { email: "" },
    errors: {}
  };

  schema = Joi.object({
    email: Joi.string()
      .trim()
      .required()
      .email({ minDomainSegments: 2, tlds: false })
      .label("label.email")
  });

  constructor(props) {
    super(props);

    this.buttonRequest = React.createRef();
  }

  doChange = async input => {};

  doSubmit = async () => {
    if (this.buttonRequest.current)
      this.buttonRequest.current.setAttribute("disabled", "disabled");

    const { data } = this.state;
    const { onCancel } = this.props;

    customerService.forgotPassword(data.email);
    toast.success(i18next.t("label.password.requested"));
    onCancel();
  };

  render() {
    const { onCancel } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <h3>
          <Trans i18nKey="label.password.forgot">Wachtwoord vergeten?</Trans>
        </h3>
        <div className="fr">
          <p>
            <Trans i18nKey="label.password.forgot.details">
              Vul hieronder het bij ons bekende e-mail adres in. We sturen
              binnen enkele minuten een e-mail waarmee een nieuw wachtwoord kan
              worden aangemaakt.
            </Trans>
          </p>
          {this.renderInput("email", "label.email", {
            useDiv: false,
            showLabel: false,
            type: "email",
            className: "full",
            lowerCase: true
          })}
        </div>
        <div className="fr">
          {this.renderButton(
            "label.password.request",
            true,
            false,
            this.buttonRequest
          )}
        </div>
        <div role="link" onClick={() => onCancel()}>
          <Trans i18nKey="label.password.known">Ik weet mijn wachtwoord</Trans>
        </div>
      </form>
    );
  }
}

export default RegisterForgotPasswordForm;

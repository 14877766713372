import React from "react";
import Section, { Steps } from "./section";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import Form from "../common/form";
import branchService from "../../services/branchService";
import { throwError } from "../../services/logService";

const { searchBranch } = window.env;

class Branch extends Form {
  state = {
    data: { search: "" },
    errors: {}
  };

  async componentDidMount() {
    if (!searchBranch || this.state.coords) return;

    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        async position => {
          this.setState({ coords: position.coords });
        },
        e => {
          // other errors appear here
          throwError("Error determining location: " + e, false);
        }
      );
    } else {
      throwError("Navigator not supported", false);
    }
  }

  async componentDidUpdate() {
    const { coords } = this.state;
    if (coords && !this.state.branches) {
      branchService
        .fetch(coords.latitude + "," + coords.longitude)
        .then(branches => this.setState({ branches, coords: null }));

      return;
    }
  }

  onSearch = () => {
    const { data } = this.state;

    branchService
      .fetch(data.search)
      .then(branches => this.setState({ branches }));
  };

  render() {
    const { currentStep, customer, branch, onChange, onSelect } = this.props;

    let branches = this.props.branches;
    if (this.state.branches) branches = this.state.branches;

    return (
      <Section
        id="location"
        title="branch.header"
        step={Steps.Branch}
        currentStep={currentStep}
        description={branch ? branch.ShortName : ""}
        onChange={onChange}
      >
        {!customer && (
          <React.Fragment>
            <div className="message note">
              <svg className="shape-icon-message-note">
                <use xlinkHref="#shape-icon-message-note" />
              </svg>{" "}
              <Trans i18nKey="account.existing">Al een account bij ons?</Trans>
              &nbsp;
              <Link to="/inloggen">
                <Trans i18nKey="label.login">Inloggen</Trans>
              </Link>
            </div>
          </React.Fragment>
        )}
        {searchBranch && (
          <div className="message">
            {this.renderInput("search", "label.search.branch", {
              useDiv: false,
              className: "text",
              showLabel: false
            })}
            <button
              onClick={this.onSearch}
              style={{ textTransform: "uppercase" }}
            >
              <Trans i18nKey="label.search">Zoek</Trans>
            </button>
          </div>
        )}

        <ul>
          {branches && branches !== "loading"
            ? branches.map(branch => (
                <li key={branch.Id} onClick={() => onSelect(branch)}>
                  <button>
                    {branch.ShortName +
                      (searchBranch && branch.Distance && branch.Distance !== 0
                        ? " (" + (branch.Distance / 1000.0).toFixed(1) + " km)"
                        : "")}
                  </button>
                </li>
              ))
            : null}
        </ul>
      </Section>
    );
  }
}

export default Branch;

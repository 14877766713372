import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
//import the loadCldr from ej2-base
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { loadCldr, L10n, addClass } from "@syncfusion/ej2-base";
import gregorianNL from "cldr-data/main/nl/ca-gregorian.json";
import numbersNL from "cldr-data/main/nl/numbers.json";
import timeZoneNamesNL from "cldr-data/main/nl/timeZoneNames.json";
import gregorianDE from "cldr-data/main/de/ca-gregorian.json";
import numbersDE from "cldr-data/main/de/numbers.json";
import timeZoneNamesDE from "cldr-data/main/de/timeZoneNames.json";
import gregorianFR from "cldr-data/main/fr/ca-gregorian.json";
import numbersFR from "cldr-data/main/fr/numbers.json";
import timeZoneNamesFR from "cldr-data/main/fr/timeZoneNames.json";
import numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import weekData from "cldr-data/supplemental/weekData.json"; // To load the culture based first day of week
import { fetchAvailabilitiesMonth } from "../../services/appointmentService";
import { EmptyKey } from "../../services/constants";
import { isValidArray } from "../common/check";
import { Trans } from "react-i18next";
import i18next from "i18next";
import Loader from "./loader";
import Warning from "./warning";
import dayjs from "dayjs";

class AppointmentCalendar extends Component {
    state = {
        navigateToMinimalDate: false,
        availabilitiesMonth: null,
        loading: true,
        language: "nl",
        refresh: false
    };

    constructor(props) {
        super(props);

        this.calendar = null;
        this.employee = null;

        this.dateCalendarMinimal = dayjs().startOf("day");
        this.dateCalendarMaximal = dayjs()
            .startOf("day")
            .add(12, "months");

        loadCldr(
            numberingSystems,
            gregorianNL,
            numbersNL,
            timeZoneNamesNL,
            gregorianDE,
            numbersDE,
            timeZoneNamesDE,
            gregorianFR,
            numbersFR,
            timeZoneNamesFR,
            weekData
        );

        L10n.load();
    }

    componentDidUpdate() {
        //console.log("componentDidUpdate");

        if (this.state.refresh) {
            this.calendar.refresh();
            this.setState({
                refresh: false,
            });
        }

        if (this.props.calendarOpen) return;

        let { language } = this.props || "nl";
        if (language === "vl") language = "nl";
        else if (language === "en") language = "en-US";

        if (this.state.language !== language) this.setState({ language });

        /*
        const { language } = this.props;
        if (this.calendar && this.calendar.globalize) {
          const currentCulture = this.calendar.globalize.culture;
    
          if (currentCulture.indexOf(language) === -1) {
            if (language === "en") this.calendar.firstDayOfWeek = 0;
            else this.calendar.firstDayOfWeek = 1;
          }
        }*/
    }

    preloadCalendar(treatments, employee, dateCalendarMinimal, language) {
        if (!employee || employee !== this.employee) {
            this.setState({
                availabilitiesMonth: null,
                loading: true,
                navigateToMinimalDate: true
            });
        }

        if (!employee) return;

        this.employee = employee;
        this.dateCalendarMinimal = dateCalendarMinimal;

        if (!this.dateCalendarMinimal)
            this.dateCalendarMinimal = dayjs().startOf("day");

        if (!isValidArray(treatments, 1) || !employee) {
            let availabilitiesMonth = [];
            availabilitiesMonth.IsError = true;

            this.setState({ availabilitiesMonth, loading: false });

            return;
        }

        const viewDate = this.dateCalendarMinimal;

        if (
            viewDate === null ||
            viewDate === undefined ||
            viewDate.month() === null ||
            viewDate.month() === undefined ||
            viewDate.month() === "NaN" ||
            viewDate.year() === null ||
            viewDate.year() === undefined ||
            viewDate.year() === "NaN"
        )
            return;

        fetchAvailabilitiesMonth(
            treatments,
            employee,
            viewDate.month() + 1, // January = 0
            viewDate.year(),
            language,
            "preloadCalendar"
        ).then(availabilitiesMonth => {
            this.setState({
                availabilitiesMonth,
                loading: false,
                navigateToMinimalDate: true
            });
        });
    }

    setMinimalDate(dateCalendarMinimal) {
        this.dateCalendarMinimal = dateCalendarMinimal;

        if (!this.dateCalendarMinimal)
            this.dateCalendarMinimal = dayjs().startOf("day");
    }

    specialDate(args, name) {
        const { employee } = this.props;
        if (
            !employee ||
            employee.Id === EmptyKey ||
            !name ||
            !args ||
            !args.element
        )
            return;

        let span = document.createElement("span");
        span.setAttribute("class", "e-icons highlight");
        args.element.firstElementChild.setAttribute(
            "title",
            i18next.t("appointment.employee.random")
        );

        addClass([args.element], ["e-day", "special", name.toLowerCase()]);
        args.element.setAttribute("data-val", name + "!");
        args.element.setAttribute("title", name + "!");
        args.element.appendChild(span);
    }

    customDates(args) {
        const { availabilitiesMonth } = this.state;
        if (!availabilitiesMonth || availabilitiesMonth.IsError) return;

        const d = args.date;
        const date = dayjs(new Date(d.getFullYear(), d.getMonth(), d.getDate()));

        const availability = availabilitiesMonth.find(f => f.Date.isSame(date));

        if (availability == null) args.isDisabled = true;
        else if (availability.IdEmployee == null) this.specialDate(args, "warning");
    }

    async onChange(args) {
        if (!args || !args.value || !args.event) return;

        const d = args.value;
        const selectedDate = dayjs(
            new Date(d.getFullYear(), d.getMonth(), d.getDate())
        );
        let employeeUnavailable = false;

        // If the employee is unavailable (warning icon), don't send the employee id.
        try {
            if (
                this.isCalendarReady() &&
                isValidArray(args.event.path, 1) &&
                args.event.path[1] &&
                args.event.path[1].className &&
                args.event.path[1].className !== null &&
                args.event.path[1].className !== undefined &&
                args.event.path[1].className.indexOf("warning") !== -1
            )
                employeeUnavailable = true;
        } catch { }

        const { onCalendarDaySelected } = this.props;
        onCalendarDaySelected(selectedDate, employeeUnavailable);
    }

    async onMonthChange(args) {
        if (!args || !args.date || args.date === null || args.date === undefined)
            return;

        const { availabilitiesMonth, loading } = this.state;
        const { treatments, employee, language } = this.props;

        //console.log("onMonthChange: " + args);

        if (loading || !isValidArray(treatments, 1)) return;

        //console.log("onMonthChange after loading: " + args);

        const d = args.date;
        const dateFirst = dayjs(new Date(d.getFullYear(), d.getMonth(), 1));
        let dateFind = dayjs(new Date(d.getFullYear(), d.getMonth(), 1)).add(
            1,
            "months"
        );
        const dateMaximum = dayjs(new Date(d.getFullYear(), d.getMonth(), 1)).add(
            11,
            "months"
        );

        if (
            !dateFirst ||
            dateFirst === null ||
            dateFirst === undefined ||
            dateFirst === "NaN" ||
            !dateFind ||
            dateFind === null ||
            dateFind === undefined ||
            dateFind === "NaN" ||
            dateFind.month() < 0 ||
            dateFind.month() === null ||
            dateFind.month() === undefined ||
            dateFind.month() === "NaN"
        )
            return;

        let setLoading = false;

        // Show loader when month not loaded yet!
        if (
            !availabilitiesMonth.find(
                f =>
                    f.Date.month() === dateFirst.month() &&
                    f.Date.year() === dateFirst.year()
            )
        )
            setLoading = true;

        if (dateFind >= dateMaximum) {
            setLoading = false;
            return;
        }

        if (
            availabilitiesMonth.find(
                f =>
                    f.Date.month() === dateFind.month() &&
                    f.Date.year() === dateFind.year()
            )
        ) {
            setLoading = false;
            return;
        }

        if (setLoading)
            this.setState({ loading: true, navigateToMinimalDate: false });

        if (this.processing) return;

        this.processing = true;

        fetchAvailabilitiesMonth(
            treatments,
            employee,
            dateFind.month() + 1, // January = 0
            dateFind.year(),
            language,
            "onMonthChange"
        ).then(availabilitiesMonthLoaded => {
            //console.log("availabilitiesMonthLoaded: " + dateFind.month());

            if (availabilitiesMonthLoaded && availabilitiesMonthLoaded.NoContent) {
                const maxDate = isValidArray(availabilitiesMonth, 1)
                    ? availabilitiesMonth[availabilitiesMonth.length - 1].Date.clone()
                    : dateFirst.clone();
                this.dateCalendarMaximal = maxDate;
                this.setState({
                    availabilitiesMonth,
                    loading: false,
                    navigateToMinimalDate: false
                });
                //console.log("availabilitiesMonthLoaded: part 1");
            } else if (
                availabilitiesMonthLoaded &&
                !availabilitiesMonthLoaded.IsError
            ) {
                const availabilitiesMonthCombined = [
                    ...availabilitiesMonthLoaded,
                    ...availabilitiesMonth
                ].sort((a, b) => a.DateTime - b.DateTime);

                this.setState({
                    availabilitiesMonth: availabilitiesMonthCombined,
                    loading: false,
                    navigateToMinimalDate: false,
                    refresh: true
                });
                //console.log("availabilitiesMonthLoaded: part 2");
            } else {
                let availabilitiesMonth = [];
                availabilitiesMonth.IsError = true;
                this.setState({
                    availabilitiesMonth,
                    loading: false,
                    navigateToMinimalDate: true
                });
                //console.log("availabilitiesMonthLoaded: part 3");
            }
            this.processing = false;
            if (this.calendar)
                this.calendar.refresh();

            //console.log("refresh calendar");
        });
    }

    isCalendarReady = () => {
        return (
            window &&
            window !== null &&
            window !== undefined &&
            window.calendar &&
            window.calendar !== null &&
            window.calendar !== undefined
        );
    };

    render() {
        const { employee, calendarOpen, overrideMinimalDate } = this.props;
        const {
            language,
            availabilitiesMonth,
            loading,
            navigateToMinimalDate
        } = this.state;

        if (!calendarOpen)
            return (
                <CalendarComponent
                    id="calendar"
                    showTodayButton={false}
                    ref={c => (this.calendar = c)}
                />
            );

        //console.log("render");

        if (overrideMinimalDate)
            this.dateCalendarMinimal = overrideMinimalDate;

        if (
            this.isCalendarReady() &&
            this.calendar &&
            this.calendar !== undefined &&
            this.calendar.isRendered &&
            this.dateCalendarMinimal &&
            navigateToMinimalDate &&
            availabilitiesMonth &&
            !availabilitiesMonth.IsError
        ) {
            this.calendar.navigateTo("Month", this.dateCalendarMinimal.toDate());
        }

        const isWarning =
            availabilitiesMonth &&
            !availabilitiesMonth.IsError &&
            availabilitiesMonth.find(f => f.IsPreferred === false);

        return (
            <React.Fragment>
                {(availabilitiesMonth &&
                    availabilitiesMonth.IsError && (
                        <Warning>
                            <Trans i18nKey="appointment.calendar.error">
                                Er is fout opgetreden bij het ophalen van de kalender. Probeer
                                opnieuw door een andere stylist te kiezen.
                            </Trans>
                        </Warning>
                    )) || (
                        <React.Fragment>
                            <LoadingOverlay
                                active={loading}
                                spinner={<Loader />}
                                styles={{
                                    overlay: base => ({
                                        ...base,
                                        background: "transparent"
                                    })
                                }}
                            >
                                <CalendarComponent
                                    id="calendar"
                                    renderDayCell={this.customDates.bind(this)}
                                    change={args => this.onChange(args)}
                                    className="e-customStyle"
                                    min={this.dateCalendarMinimal.toDate()}
                                    max={this.dateCalendarMaximal.toDate()}
                                    showTodayButton={false}
                                    navigated={args => this.onMonthChange(args)}
                                    locale={language}
                                    ref={c => (this.calendar = c)}
                                />
                            </LoadingOverlay>
                            {employee &&
                                employee.Id !== EmptyKey &&
                                isWarning && (
                                    <div>
                                        <br />
                                        <Warning>
                                            <Trans i18nKey="appointment.employee.unavailable.date">
                                                {{
                                                    employeeFirstName: employee.FirstName
                                                }}{" "}
                                            </Trans>
                                        </Warning>
                                    </div>
                                )}
                        </React.Fragment>
                    )}
            </React.Fragment>
        );
    }
}

export default AppointmentCalendar;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import i18next from "i18next";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import RegisterSubscriptionForm from "./forms/register-subscription";
import customerService from "../services/customerService";
import subscriptionService from "../services/subscriptionService";
import { isValidArray, formatPrice, fromISO } from "./common/check";

dayjs.extend(localizedFormat);

class MySubscriptions extends Component {
  state = {};

  async componentDidMount() {
    if (!this.props.customer) {
      subscriptionService
        .fetchSubscriptions()
        .then(subscriptions => this.setState({ subscriptions }));
      return;
    }

    const { language, countries } = this.props;

    const customer = await customerService.refresh(
      this.props.customer,
      language,
      countries
    );
    if (!customer) window.location = "/uitloggen";

    const customerSubscriptions = await subscriptionService.fetchCustomerSubscriptions(
      customer
    );

    let subscriptions = await subscriptionService.fetchSubscriptions();
    if (!isValidArray(customerSubscriptions, 1)) {
      this.setState({ subscriptions });

      return;
    }

    customerSubscriptions.forEach(
      customerSubscription =>
        (subscriptions = subscriptions.filter(
          f => f.Id !== customerSubscription.Id
        ))
    );

    this.setState({ subscriptions, customerSubscriptions });
  }

  onCreateSubscription = subscription => {
    this.setState({ subscriptionSelected: subscription });
  };

  onCancelSubscription = (subscriptions, action) => {
    const customerSubscriptions = [...this.state.customerSubscriptions];
    customerSubscriptions.forEach(item => (item.confirmCancel = null));

    const index = customerSubscriptions.indexOf(subscriptions);
    customerSubscriptions[index].confirmCancel = action;

    this.setState({ customerSubscriptions });
  };

  handleCancelSubscription = async subscription => {
    const { customer } = this.props;
    const result = await subscriptionService.cancel(customer, subscription);

    if (result && !result.IsError) {
      toast.success(
        i18next.t("subscriptions.cancelled", { date: dayjs().format("LL") })
      );

      const customerSubscriptions = this.state.customerSubscriptions.filter(
        f => f.Id !== subscription.Id
      );
      this.setState({ customerSubscriptions });
    }
  };

  render() {
      document.title = i18next.t("navigation.mysubscriptions")
      .replace("<0>", "")
      .replace("</0>", "");

    const { customer, language } = this.props;

    dayjs.locale(language);

    const {
      subscriptions,
      customerSubscriptions,
      subscriptionSelected
    } = this.state;

    return (
      <section id="mysubscriptions" className="account">
        <header>
          <div id="icon-sub">
            <svg className="shape-icon-subscriptions">
              <use xlinkHref="#shape-icon-subscriptions" />
            </svg>
          </div>
          <div>
            <Trans i18nKey="subscriptions.header">
              <h1>Jouw abonnementen</h1>
            </Trans>
            <Trans i18nKey="subscriptions.description">
              <p>
                Het hele jaar door onbeperkt knippen en/of kleuren voor een vast
                én laag maandtarief.
              </p>
            </Trans>
          </div>
        </header>
        <div className="body">
          <div id="selectedsubs">
            {!customer || !isValidArray(customerSubscriptions, 1) ? (
              <React.Fragment>
                {/* Kolom 1: Heeft nog geen abonnement */}
                <div id="selectedsubs">
                  <aside>
                    <Trans i18nKey="subscriptions.promotion.long">
                      <h2>Hoe werkt het?</h2>
                      <ul>
                        <li>Betaal elke maand een vast maandbedrag</li>
                        <li>
                          Vaker naar de kapper: er altijd verzorgd uitzien
                        </li>
                        <li>Het abonnement wordt automatisch geïncasseerd</li>
                        <li>
                          Je sluit het abonnement voor minimaal 12 maanden af
                        </li>
                        <li>Je kunt 1 of meerdere abonnementen kiezen</li>
                      </ul>
                    </Trans>
                  </aside>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* Kolom 1: Heeft al een abonnement */}
                <Trans i18nKey="subscriptions.header">
                  <h2>Jouw abonnementen</h2>
                </Trans>
                <table>
                  <tbody>
                    {customerSubscriptions.map(subscription => (
                      <React.Fragment key={subscription.Id}>
                        <tr>
                          <th>{subscription.Description}</th>
                          <td>
                            €{" "}
                            {formatPrice(subscription.Price) +
                              " " +
                              i18next.t("subscriptions.monthly")}
                          </td>
                          <td colSpan={subscription.DateExpire ? 1 : 2}>
                            {i18next.t("label.from") +
                              " " +
                              fromISO(subscription.DatePurchase).format("LL")}
                          </td>
                          {subscription.DateExpire && (
                            <td>
                              {i18next.t("label.untill") +
                                " " +
                                fromISO(subscription.DateExpire).format("LL")}
                            </td>
                          )}
                          {!subscription.confirmCancel && (
                            <React.Fragment>
                              {subscription.IsCancellationAllowed ? (
                                <td>
                                  <span
                                    role="link"
                                    onClick={() =>
                                      this.onCancelSubscription(
                                        subscription,
                                        true
                                      )
                                    }
                                  >
                                    <Trans i18nKey="label.terminate" />
                                  </span>
                                </td>
                              ) : (
                                <td> </td>
                              )}
                            </React.Fragment>
                          )}
                        </tr>
                        {subscription.confirmCancel && (
                          <tr>
                            <td colSpan="2">
                              <Trans i18nKey="subscriptions.cancel" />
                            </td>
                            <td>
                              {" "}
                              <span
                                role="link"
                                onClick={() =>
                                  this.handleCancelSubscription(subscription)
                                }
                              >
                                <Trans i18nKey="label.yes" />
                              </span>{" "}
                              /{" "}
                              <span
                                role="link"
                                onClick={() =>
                                  this.onCancelSubscription(subscription, false)
                                }
                              >
                                <Trans i18nKey="label.no" />
                              </span>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
          {isValidArray(subscriptions, 1) && (
            <div id="availablesubs">
              {/* Kolom 2: Heeft nog geen abonnement */}
              {!subscriptionSelected ? (
                <React.Fragment>
                  {isValidArray(customerSubscriptions, 1) ? (
                    <React.Fragment>
                      <Trans i18nKey="subscriptions.choose.another">
                        <h2>Kies nog een abonnement</h2>
                        <p>Voor nog meer voordelen.</p>
                      </Trans>
                    </React.Fragment>
                  ) : (
                    <Trans i18nKey="subscriptions.choose">
                      <h2>Kies een abonnement</h2>
                    </Trans>
                  )}
                  <table>
                    <tbody>
                      {subscriptions.map(subscription => (
                        <React.Fragment key={subscription.Id}>
                          <tr>
                            <th>{subscription.Description}</th>
                            <td
                              align="right"
                              colSpan={subscription.confirmCreate ? 2 : 1}
                            >
                              €{" "}
                              {formatPrice(subscription.Price) +
                                " " +
                                i18next.t("subscriptions.monthly")}
                            </td>
                            <td>
                              {customer ? (
                                <span
                                  role="link"
                                  onClick={() =>
                                    this.onCreateSubscription(subscription)
                                  }
                                >
                                  <Trans i18nKey="subscriptions.subscribe">
                                    Abonnement afsluiten
                                  </Trans>
                                </span>
                              ) : (
                                <span role="link">
                                  <Link to="/registreer">
                                    <Trans i18nKey="subscriptions.subscribe">
                                      Abonnement afsluiten
                                    </Trans>
                                  </Link>
                                </span>
                              )}{" "}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span
                    role="link"
                    onClick={() => this.onCreateSubscription(null)}
                  >
                    &lt;{" "}
                    <Trans i18nKey="subscriptions.buy.back">
                      Terug naar het overzicht
                    </Trans>
                  </span>
                  <h2>{subscriptionSelected.Description}</h2>
                  <Trans i18nKey="subscriptions.buy.header">
                    <p>{{ price: formatPrice(subscriptionSelected.Price) }}</p>
                  </Trans>
                  <div>
                    <RegisterSubscriptionForm
                      customer={customer}
                      subscription={subscriptionSelected}
                      onCreateSubscription={this.onCreateSubscription}
                      language={language}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default MySubscriptions;

import React from "react";
import { Trans } from "react-i18next";
import Treatment from "./treatment";
import { isValidArray } from "../common/check";

const TreatmentGroup = ({ language, treatmentGroup, onSelect, isOpen }) => {
  return (
    <div
      className={!isOpen ? "accordion-item" : "accordion-item open"}
      style={
        treatmentGroup && isValidArray(treatmentGroup.treatments, 1)
          ? { all: "unset" }
          : { display: "none" }
      }
    >
      <header>
        <svg className={treatmentGroup.style}>
          <use xlinkHref={treatmentGroup.shape} />
        </svg>
        <Trans>
          <h3>{treatmentGroup.category}</h3>
        </Trans>
        <svg className="shape-icon-arrow down">
          <use xlinkHref="#shape-icon-arrow" />
        </svg>
      </header>
      <div className="accordion-body">
        <table>
          <tbody>
            {treatmentGroup.treatments.map(treatment => (
              <Treatment
                language={language}
                key={treatment.Id}
                treatment={treatment}
                onSelect={onSelect}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TreatmentGroup;

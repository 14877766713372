import React from "react";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import i18next from "i18next";
import Joi from "joi";
import Form from "./common/form";
import PaymentMethod from "./appointments/paymentMethod";
import { fetchPaymentMethods } from "../services/appointmentService";
import {
  getIcon,
  fetchCustomAmounts,
  createGiftcard
} from "../services/giftcardService";

class Giftcard extends Form {
  state = {
    data: {
      CustomAmount: "",
      ReceiverName: "",
      ReceiverEmail: "",
      SenderName: "",
      SenderEmail: "",
      Message: ""
    },
    errors: {},
    selectedAmount: 25,
    paymentMethods: [],
    selectedPaymentMethod: "ideal"
  };

  schema = Joi.object({
    CustomAmount: Joi.number()
      .greater(5)
      .optional()
      .allow(null)
      .allow("")
      .label("label.giftcard.amount"),
    ReceiverName: Joi.string()
      .required()
      .label("label.name.receiver"),
    ReceiverEmail: Joi.string()
      .trim()
      .required()
      .email({ minDomainSegments: 2, tlds: false })
      .label("label.email.receiver"),
    SenderName: Joi.string()
      .allow("")
      .label("label.name.sender"),
    SenderEmail: Joi.string()
      .trim()
      .allow("")
      .email({ minDomainSegments: 2, tlds: false })
      .label("label.email.sender"),
    Message: Joi.string()
      .trim()
      .optional()
      .allow(null)
      .allow("")
      .label("label.giftcard.message")
  });

  async componentDidMount() {
    const { customer } = this.props;

    const customAmounts = await fetchCustomAmounts();

    if (customer) {
      const { data } = this.state;
      data.SenderName = customer.FullName;
      data.SenderEmail = customer.Email;

      this.setState({ data, customer, customAmounts });
    } else this.setState({ customAmounts });

    if (customAmounts) this.setState({ selectedAmount: 0 });

    this.loadPaymentMethods();
  }

  loadPaymentMethods = async () => {
    if (!this.state.paymentMethods || this.state.paymentMethods.length === 0) {
      this.setState({ paymentMethods: ["ideal"] });

      if (!window.env.payment) return;

      fetchPaymentMethods().then(paymentMethods => {
        if (paymentMethods && paymentMethods.length > 0)
          this.setState({ paymentMethods });
      });
    }
  };

  selectPaymentMethod = paymentMethod => {
    this.setState({
      selectedPaymentMethod: paymentMethod,
      disableSubmit: true
    });
  };

  doChange = () => {};

  onSelect = amount => {
    const { data } = this.state;
    data.CustomAmount = "";
    this.setState({ selectedAmount: amount, disableSubmit: true, data });
  };

  onPayment = paymentMethod => {
    this.setState({ disableSubmit: false, paymentMethod });
  };

  doSubmit = async () => {
    const { data, paymentMethod } = this.state;
    const { customer } = this.props;
    const amount =
      data.CustomAmount !== null && data.CustomAmount !== ""
        ? data.CustomAmount
        : this.state.selectedAmount;

    if (amount <= 0) {
      toast.error(i18next.t("giftcard.choose-amount"));
      return;
    }

    const paymentUrl = await createGiftcard(
      customer,
      amount * 100,
      data.ReceiverName,
      data.ReceiverEmail,
      data.SenderName,
      data.SenderEmail,
      data.Message,
      window.location.href + "betaalstatus?",
      paymentMethod
    );

    window.location = paymentUrl;
  };

  render() {
    document.title = i18next
      .t("navigation.giftcard")
      .replace("<0>", "")
      .replace("</0>", "");

    const {
      selectedAmount,
      paymentMethods,
      selectedPaymentMethod
    } = this.state;
    let { customAmounts } = this.state;
    const amounts = [5, 10, 15, 20, 25, 30, 35, 50, 75, 100];
    const icon = getIcon();
    const forceCustomAmount =
      customAmounts && customAmounts.some(s => s.AmountPaid === 999);
    customAmounts =
      customAmounts &&
      customAmounts.filter(f => {
        return f.AmountPaid !== 999;
      });

    return (
      <section id="giftcard" className="account">
        <header className="themeBGColor">
          <svg className="shape-icon-giftcard">
            <use xlinkHref="#shape-icon-giftcard" />
          </svg>
          <div>
            <Trans i18nKey="giftcard.header">
              <h1>Cadeaubon vanaf € 5,–</h1>
            </Trans>
            <Trans i18nKey="giftcard.description">
              <p>
                Steun ons tijdens de lockdown en verwen iemand (of jezelf!) met
                een giftcard, onbeperkt geldig!
              </p>
            </Trans>
          </div>
          {icon && (
            <svg className={icon}>
              <use xlinkHref={"#" + icon} />
            </svg>
          )}
        </header>
        <div className="body">
          <form onSubmit={this.handleSubmit}>
            <div id="giftcard-amount">
              <Trans i18nKey="giftcard.choose-amount">
                <h2>Kies een waarde</h2>
              </Trans>
              {!customAmounts ? (
                <ul>
                  {amounts.map(amount => (
                    <li key={amount}>
                      <button
                        id={"disabled-" + amount}
                        className={
                          selectedAmount === amount ? "selected" : null
                        }
                        onClick={() => this.onSelect(amount)}
                      >
                        € {amount}
                      </button>
                    </li>
                  ))}
                  <li key="custom">
                    €{" "}
                    {this.renderInput("CustomAmount", "label.giftcard.amount", {
                      useDiv: false,
                      showLabel: false,
                      className: "full",
                      maxLength: 3
                    })}
                  </li>
                </ul>
              ) : (
                <ul>
                  {customAmounts.map(customAmount => (
                    <li key={customAmount.AmountPaid}>
                      <button
                        id={"disabled-" + customAmount.AmountPaid}
                        className={
                          selectedAmount === customAmount.AmountPaid
                            ? "selected"
                            : null
                        }
                        style={{ width: 150 }}
                        onClick={() => this.onSelect(customAmount.AmountPaid)}
                      >
                        {customAmount.Amount === customAmount.AmountPaid
                          ? "€ " + customAmount.AmountPaid
                          : "€ " +
                            customAmount.Amount +
                            i18next.t("label.for") +
                            "€ " +
                            customAmount.AmountPaid}
                      </button>
                    </li>
                  ))}
                  {forceCustomAmount && (
                    <li key="custom">
                      €{" "}
                      {this.renderInput(
                        "CustomAmount",
                        "label.giftcard.amount",
                        {
                          useDiv: false,
                          showLabel: false,
                          className: "full",
                          maxLength: 3
                        }
                      )}
                    </li>
                  )}
                </ul>
              )}
            </div>
            <div id="giftcard-form">
              <Trans i18nKey="giftcard.data">
                <h2>Gegevens voor de digitale giftcard</h2>
              </Trans>
              <div className="fr columns">
                <div className="frColumn">
                  {this.renderInput("ReceiverName", "label.name.receiver", {
                    useDiv: false,
                    showLabel: false,
                    className: "full",
                    maxLength: 80
                  })}
                </div>
                <div className="frColumn">
                  {this.renderInput("ReceiverEmail", "label.email.receiver", {
                    type: "email",
                    useDiv: false,
                    showLabel: false,
                    className: "full",
                    lowerCase: true,
                    maxLength: 255
                  })}
                  {/* <div role="link">Nog een ontvanger toevoegen...</div> */}
                </div>
              </div>
              {this.renderTextArea("Message", "label.giftcard.message", {
                useDiv: true,
                maxLength: 500,
                className: "full"
              })}
              <div className="fr columns">
                <div className="frColumn">
                  {this.renderInput("SenderName", "label.name.sender", {
                    useDiv: false,
                    showLabel: false,
                    className: "full",
                    maxLength: 80
                  })}
                </div>
                <div className="frColumn">
                  {this.renderInput("SenderEmail", "label.email.sender", {
                    type: "email",
                    useDiv: false,
                    showLabel: false,
                    className: "full",
                    lowerCase: true,
                    maxLength: 255
                  })}
                </div>
              </div>
            </div>
            <div id="giftcard-send">
              <Trans i18nKey="giftcard.payment">
                <h2>Afrekenen &amp; versturen</h2>
              </Trans>
              <Trans i18nKey="giftcard.payment.description">
                <p>
                  De ontvanger zal een mailtje ontvangen met daarin de giftcard.
                  De verzender krijgt per e-mail een bevestiging.
                </p>
              </Trans>
              <div className="fr columns">
                <div id="paymentOptions">
                  {paymentMethods.map(paymentMethod => (
                    <PaymentMethod
                      key={paymentMethod}
                      paymentMethod={paymentMethod}
                      selectedPaymentMethod={selectedPaymentMethod}
                      selectPaymentMethod={this.selectPaymentMethod}
                      onGiftcardPayment={this.onPayment}
                    />
                  ))}
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default Giftcard;

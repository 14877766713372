import React, { Component } from "react";
import { Trans } from "react-i18next";
import customerService from "../services/customerService";

class Logout extends Component {
  componentDidMount() {
    customerService.logout();

    this.props.onLogout();
    //window.location = "/";
  }

  render() {
    return (
      <section className="account">
        <header>
          <Trans i18nKey="logout.logged-out.header">
            <h1>Uitgelogd</h1>
          </Trans>
          <Trans i18nKey="logout.logged-out.details">
            <p>Je bent uitgelogd.</p>
          </Trans>
          <div role="link">
            <Trans i18nKey="label.login">Inloggen</Trans>
          </div>
        </header>
      </section>
    );
  }
}

export default Logout;

import React, { Component } from "react";
import { Trans } from "react-i18next";

class UpdatePasswordSuccess extends Component {
  state = {};
  render() {
    return (
      <section id="forgotPasswordSuccess" className="modal">
        <header>
          <Trans i18nKey="navigation.update-password-success">
            <h2>Het wachtwoord is gewijzigd!</h2>
          </Trans>
        </header>
        <div className="body">
          <p>
            <Trans i18nKey="label.password.change.success">
              Indien tijdens een afspraak het wachtwoord was vergeten, ga dan
              naar het venster of tabblad van de afspraak en gebruik het zojuist
              gekozen wachtwoord.
            </Trans>
          </p>
        </div>
      </section>
    );
  }
}

export default UpdatePasswordSuccess;

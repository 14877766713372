import React from "react";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import i18next from "i18next";
import queryString from "query-string";
import Joi from "joi";
import Form from "../common/form";
import customerService from "../../services/customerService";

class UpdatePassword extends Form {
  state = {
    guid: null,
    data: {
      PasswordOld: "",
      Password: "",
      PasswordConfirm: ""
    },
    errors: {}
  };

  schema = Joi.object({
    PasswordOld: Joi.string()
      .allow("")
      .label("label.password.old"),
    Password: Joi.string()
      .min(5)
      .max(25)
      .required()
      .strict()
      .label("label.password"),
    PasswordConfirm: Joi.string()
      .required()
      .strict()
      .valid(Joi.ref("Password"))
      .label("label.password.confirm")
  });

  componentDidMount() {
    if (window.location) {
      const params = queryString.parse(window.location.search);
      this.setState({ guid: params.id });
    }

    if (this.props.guid) this.setState({ guid: this.props.guid });
  }

  doChange = async input => {};

  doSubmit = async () => {
    const { guid, data } = this.state;

    const result = await customerService.updatePassword(
      guid,
      data.Password,
      data.PasswordOld
    );

    if (result && !result.IsError) window.location = "/wachtwoord-gewijzigd";
    else toast.error(i18next.t("label.password.change.failed"));
  };

  render() {
    return (
      <section
        id="forgotPassword"
        className={!this.props.account ? "modal" : "fr"}
      >
        {!this.props.account && (
          <header>
            <Trans i18nKey="navigation.update-password">
              <h2>Wijzig het wachtwoord</h2>
            </Trans>
          </header>
        )}
        <div className={!this.props.account ? "body" : "fr"}>
          <form
            onSubmit={!this.props.account ? this.handleSubmit : this.doSubmit}
          >
            {this.props.account &&
              this.renderInput("PasswordOld", "label.password.old", {
                showLabel: false,
                useDiv: true,
                className: "full",
                type: "password"
              })}

            {this.renderInput("Password", "label.password.new", {
              showLabel: false,
              useDiv: true,
              className: "full",
              type: "password"
            })}
            {this.renderInput("PasswordConfirm", "label.password.new.confirm", {
              showLabel: false,
              useDiv: true,
              className: "full",
              type: "password"
            })}

            <div className="fr">
              {this.renderButton("label.password.change", true)}
              {this.props.account && (
                <span role="link" onClick={() => this.props.onCancel()}>
                  &nbsp;&nbsp;<Trans i18nKey="label.cancel">Annuleren</Trans>
                </span>
              )}
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default UpdatePassword;

import http from "./httpService";
import { throwError } from "./logService";
import { fromISO, toISO, isValidArray } from "../components/common/check";
import { EmptyKey } from "./constants";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import i18next from "i18next";

const { apiUrl, license, payment, debug } = window.env;
let retries;

dayjs.extend(localizedFormat);

function apiEndpoint() {
    return !debug
        ? apiUrl + "/appointment/" + (window.branch ? window.branch : license) + "/"
        : "https://localhost:44357/AppointmentService.svc/" +
        (window.branch ? window.branch : license) +
        "/";
}

export function generateTreatmentList(treatments) {
    if (!isValidArray(treatments, 1)) return [];

    let treatmentList = [];
    treatments.forEach(treatment => {
        if (treatment.Selected === "selected") treatmentList.push(treatment.Id);
    });

    if (treatments.Promotion) treatmentList.push(treatments.Promotion.Id);

    return treatmentList;
}

export function treatmentsSelected(treatments) {
    if (!isValidArray(treatments, 1)) return [];

    try {
        return treatments.filter(treatment => treatment.Selected === "selected");
    } catch {
        return [];
    }
}

export function treatmentsPriceTotal(treatments) {
    if (!isValidArray(treatments, 1)) return 0;

    try {
        const treatmentSelected = treatmentsSelected(treatments);

        let totalPrice = 0;

        for (let treatment of treatmentSelected) {
            totalPrice =
                (treatment.Price ? totalPrice + treatment.Price : totalPrice) +
                (treatment.Promotion &&
                    treatment.Promotion.Selected &&
                    treatment.Promotion.Price
                    ? treatment.Promotion.Price
                    : 0);
        }

        return totalPrice;
    } catch {
        return 0;
    }
}

export function treatmentsDepositTotal(treatments, customer) {
    if (!payment) return 0;
    if (!isValidArray(treatments, 1)) return 0;

    // No deposit required for diamond and star customers
    if (customer && (customer.IsDiamond || customer.IsStar)) return 0;

    try {
        const treatmentSelected = treatmentsSelected(treatments);

        let totalDeposit = 0;

        for (let treatment of treatmentSelected) {
            totalDeposit = treatment.AmountDeposit
                ? totalDeposit + treatment.AmountDeposit
                : totalDeposit;
        }

        return totalDeposit;
    } catch {
        return 0;
    }
}

export function isTreatmentSelected(treatments) {
    return treatmentsSelected(treatments).length > 0;
}

export function translateDescription(treatment, language) {
    return treatment &&
        language &&
        language.length === 2 &&
        treatment.DescriptionTranslations &&
        treatment.DescriptionTranslations.find(
            f => f.Language === language.toUpperCase()
        )
        ? treatment.DescriptionTranslations.find(
            f => f.Language === language.toUpperCase()
        ).Description
        : treatment.Description;
}

export function translateRemark(treatment, language) {
    return treatment &&
        language &&
        language.length === 2 &&
        treatment.RemarkTranslations &&
        treatment.RemarkTranslations.find(
            f => f.Language === language.toUpperCase()
        )
        ? treatment.RemarkTranslations.find(
            f => f.Language === language.toUpperCase()
        ).Description
        : treatment.Remark;
}

export function treatmentDescription(treatments, language) {
    if (!isValidArray(treatments, 1)) return [];

    let treatmentDescription = "";

    const treatmentsSelected = treatments.filter(
        treatment => treatment.Selected === "selected"
    );

    if (treatmentsSelected.length === 1)
        treatmentDescription = translateDescription(
            treatmentsSelected[0],
            language
        );
    else if (treatmentsSelected.length > 1) {
        treatmentDescription = i18next.t("treatment.multiple");
    }

    if (treatments.Promotion)
        treatmentDescription =
            treatmentDescription +
            " + " +
            translateDescription(treatments.Promotion, language);

    return treatmentDescription;
}

export async function fetchTreatments() {
    let result;
    try {
        result = await http.get(apiEndpoint() + "FetchTreatments", 5000, true);

        if (!result) return throwError("No result from fetchTreatments.", true);

        if (result.NoContent)
            return throwError("No treatments from fetchTreatments.", true);
    } catch {
        setTimeout(() => {
            return fetchTreatments();
        }, 3000);
    }

    if (!result) return;
    const treatments = result.Value;
    if (!treatments) return;

    for (let treatment of treatments) {
        if (treatment.IndicatorGender === "\0") treatment.IndicatorGender = null;
    }

    return treatments;
}

function transformAvailabilities(
    availabilities,
    language,
    employeeUnavailable
) {
    dayjs.locale(language);

    for (let availability of availabilities) {
        const dateTime = fromISO(availability.DateTime);
        availability.DateTime = dateTime;

        availability.Date = dateTime.clone().startOf("day");
        availability.Time = dateTime.format("HH:mm");
        availability.TimeValue = dateTime.hour() * 60 + dateTime.minute();
        availability.FullDate = dateTime.format("LL");
        availability.FullDateTime = dateTime.format("dddd LL - HH:mm");

        if (employeeUnavailable) availability.IsPreferred = false;
    }

    return availabilities;
}

async function fetchAvailability(date, employee, treatmentList) {
    if (!date) return { NoContent: true };

    let dateISO = toISO(date);
    let queryString = `?date=${dateISO}&useFullList=true`;

    if (treatmentList.length === 1)
        queryString += `&idTreatment=${treatmentList[0]}`;
    else queryString += `&treatments=${treatmentList}`;

    if (employee && employee != null && employee != undefined && employee.Id !== EmptyKey)
        queryString += `&idEmployee=${employee.Id}`;

    const result = await http.get(
        apiEndpoint() + "FetchAvailabilitiesDay" + queryString
    );

    result.QueryString = queryString;

    return result;
}

export async function fetchAvailabilitiesDay(
    treatments,
    employee,
    date,
    employeeUnavailable,
    language
) {
    const treatmentList = generateTreatmentList(treatments);

    if (!treatmentList || treatmentList.length === 0)
        return throwError("No treatments selected in fetchAvailabilitiesDay.");

    if (employeeUnavailable) employee = null;

    const result = await fetchAvailability(date, employee, treatmentList);

    if (result.NoContent)
        return throwError(
            "No availability from fetchAvailabilitiesDay for: FetchAvailabilitiesDay" +
            result.QueryString,
            true
        );

    if (result.IsError) return result;

    const availabilities = result.Value;
    return transformAvailabilities(availabilities, language, employeeUnavailable);
}

export async function fetchAvailabilitiesShort(treatments, employee, language) {
    let availableDays = 0;
    let availabilities = [];

    const treatmentList = generateTreatmentList(treatments);

    if (!treatmentList || treatmentList.length === 0)
        return throwError("No treatments selected in fetchAvailabilitiesShort.");

    for (let i = 0; i <= 5; i++) {
        let date = dayjs().add(i, "day");
        const result = await fetchAvailability(date, employee, treatmentList);

        if (result.NoContent) continue;

        if (result.IsError)
            return throwError(
                "Error from fetchAvailabilitiesShort for: FetchAvailabilitiesDay" +
                result.QueryString +
                " ,details: " +
                result.ErrorMessage +
                "."
            );

        const availabilitiesSelect = result.Value;

        if (
            !availabilitiesSelect ||
            availabilitiesSelect === null ||
            availabilitiesSelect.length <= 0
        )
            continue;

        availableDays++;
        availabilities = [...availabilities, ...availabilitiesSelect];

        if (availableDays >= 2) break;
    }

    if (availableDays <= 1) {
        let date = dayjs().add(6, "day");

        let nextAvailableDays = await fetchAvailabilitiesMonth(
            treatments,
            employee,
            date.month() + 1,
            date.year(),
            language,
            "fetchAvailabilitiesShort"
        );
        /*
        nextAvailableDays = nextAvailableDays.filter(
          f => f.IdEmployee === employee.Id
        );*/

        for (let i = 0; i <= 5; i++) {
            if (i >= nextAvailableDays.length) break;

            if (!nextAvailableDays[i] || !nextAvailableDays[i].Date) continue;

            const result = await fetchAvailability(
                nextAvailableDays[i].Date,
                employee,
                treatmentList
            );

            if (result.NoContent) continue;

            const availabilitiesSelect = result.Value;

            if (
                !availabilitiesSelect ||
                availabilitiesSelect === null ||
                availabilitiesSelect.length <= 0
            )
                continue;

            availableDays++;
            availabilities = [...availabilities, ...availabilitiesSelect];

            if (availableDays >= 2) break;
        }
    }

    availabilities = transformAvailabilities(availabilities, language);
    if (!availabilities || availabilities.length === 0) availabilities.IsError = true;

    return availabilities;
}

export async function fetchAvailabilitiesMonth(
    treatments,
    employee,
    month,
    year,
    language,
    caller,
    retry
) {
    const treatmentList = generateTreatmentList(treatments);
    if (!treatmentList || treatmentList.length === 0)
        return throwError("No treatments selected in fetchAvailabilitiesMonth.");

    if (!month || month === null || month === undefined || month === "NaN")
        return throwError(
            "Empty month in fetchAvailabilitiesMonth (" + caller + ")."
        );

    if (retry === undefined) retries = 0;

    let queryString = `?month=${month}&year=${year}`;

    if (treatmentList.length === 1)
        queryString += `&idTreatment=${treatmentList[0]}`;
    else queryString += `&treatments=${treatmentList}`;

    if (employee && employee != null && employee != undefined && employee.Id !== EmptyKey)
        queryString += `&idEmployee=${employee.Id}`;

    const result = await http.get(
        apiEndpoint() + "FetchAvailabilitiesMonth" + queryString,
        40000
    );

    // When empty at the end of the month, skip one month (for very busy salons)
    if (result.NoContent && !retry) {
        retries++;

        if (month !== 12) month += 1;
        else {
            month = 1;
            year += 1;
        }
        return fetchAvailabilitiesMonth(
            treatments,
            employee,
            month,
            year,
            language,
            "self",
            retries >= 2
        );
    } else if (result.NoContent)
        return throwError(
            "No availability from fetchAvailabilitiesMonth for: FetchAvailabilitiesMonth" +
            queryString,
            true
        );

    if (result.IsError) return result;

    const availabilities = transformAvailabilities(result.Value, language);
    return availabilities;
}

export async function createAppointment(
    treatments,
    availability,
    employee,
    customer,
    customerFamilyMember,
    hairstyle,
    validationCode,
    silentChair,
    remark,
    customerName,
    telephone,
    telephoneCountryCode,
    email,
    language
) {
    const treatmentList = generateTreatmentList(treatments);
    if (!treatmentList || treatmentList.length === 0)
        return throwError("No treatments selected in createAppointment.");

    const indicatorHairlength =
        hairstyle.IndicatorGender === "F"
            ? hairstyle.Indicator.replace("F", "")
            : null;

    const amountDeposit = payment ? treatmentsDepositTotal(treatments) : 0;

    let queryString = `?dateTime=${toISO(availability.DateTime)}`;

    if (treatmentList.length === 1)
        queryString += `&idTreatment=${treatmentList[0]}`;
    else queryString += `&treatments=${treatmentList}`;

    if (employee && employee != null && employee != undefined && employee.Id !== EmptyKey)
        queryString =
            queryString +
            `&isPreferred=${employee.IsPreferred ? employee.IsPreferred : false
            }&idEmployee=${employee.Id}`;
    else queryString = queryString + `&idEmployee=${availability.IdEmployee}`;

    if (silentChair)
        queryString = queryString + `&isSilent=true`;

    if (telephoneCountryCode)
        telephoneCountryCode = telephoneCountryCode.replace("+", "%2b");

    if (customer && !customerFamilyMember) {
        queryString = queryString + `&idCustomer=${customer.Id}`;
        if (indicatorHairlength)
            queryString = queryString + `&indicatorHairlength=${indicatorHairlength}`;
    } else if (customerFamilyMember) {
        queryString = queryString + `&idCustomer=${customerFamilyMember.Id}`;
        queryString = queryString + `&email=${customer.Email}`;
        remark =
            (remark ? remark + ". " : "") +
            i18next.t("label.appointment.made-by") +
            customer.FullName;
    } else
        queryString =
            queryString +
            `&customerName=${customerName}&indicatorGender=${hairstyle.IndicatorGender
            }&telephone=${telephone}&telephoneCountryCode=${telephoneCountryCode}&email=${email}`;
    if (remark && remark.trim().length > 2)
        queryString = queryString + `&remark=${remark}`;

    queryString = queryString + `&validationCode=${validationCode}`;

    const depositNeeded = !(customer && (customer.IsDiamond || customer.IsStar));

    if (amountDeposit !== 0 && depositNeeded)
        queryString = queryString + `&isPending=true`;

    if (language && language !== "nl")
        queryString = queryString + `&language=${language}`;

    const result = await http.post(
        apiEndpoint() + "CreateAppointment" + queryString
    );

    if (result.NoContent)
        throwError("No availability for: CreateAppointment" + queryString, true);

    if (result.Value === "" || result.Value === EmptyKey) {
        result.IsError = true;
        throwError("Empty result for: CreateAppointment" + queryString);
    }

    if (indicatorHairlength && customer)
        customer.IndicatorHairlength = indicatorHairlength;

    return result;
}

export async function changeAppointment(
    idAppointment,
    treatments,
    availability,
    employee,
    remark
) {
    const treatmentList = generateTreatmentList(treatments);
    if (!treatmentList || treatmentList.length === 0)
        return throwError("No treatments selected in changeAppointment.");

    let queryString = `&dateTime=${toISO(availability.DateTime)}`;

    if (treatmentList.length === 1)
        queryString += `&idTreatment=${treatmentList[0]}`;
    else queryString += `&treatments=${treatmentList}`;

    if (employee)
        queryString =
            queryString +
            `&isPreferred=${employee.IsPreferred ? employee.IsPreferred : false
            }&idEmployee=${employee.Id}`;
    else queryString = queryString + `&idEmployee=${availability.IdEmployee}`;

    if (remark && remark.trim().length > 2)
        queryString = queryString + `&remark=${remark}`;

    const result = await http.post(
        apiEndpoint() +
        "ChangeAppointment?idAppointment=" +
        idAppointment +
        queryString
    );

    if (result.NoContent)
        throwError("No availability for: ChangeAppointment" + queryString, true);

    if (result.Value === "" || result.Value === EmptyKey) {
        result.IsError = true;
        throwError("Empty result for: ChangeAppointment" + queryString);
    }

    return result;
}

export async function fetchPaymentMethods() {
    const { IsError: isError, Value: paymentMethods } = await http.get(
        apiEndpoint() + "FetchPaymentMethods"
    );

    if (isError) return [];

    return paymentMethods;
}

export async function payAppointment(
    idAppointment,
    price,
    redirectUrl,
    paymentMethod,
    customer,
    passby
) {
    //if (customer)
        //customer.FutureAppointments = null;

    let result = await http.post(
        apiEndpoint() + "Pay",
        {
            idAppointment,
            price,
            redirectUrl,
            paymentMethod,
            customer,
            passbyName: passby && passby.Name,
            passbyEmail: passby && passby.Email
        }
    );

    let isError = result.IsError;
    let paymentUrl = result.Value;

    let retries = 3;
    while (isError && retries >= 0)
    {
        await setTimeout(() => { }, 3000);

        result = await http.post(
            apiEndpoint() + "Pay",
            {
                idAppointment,
                price,
                redirectUrl,
                paymentMethod,
                customer,
                passbyName: passby && passby.Name,
                passbyEmail: passby && passby.Email
            }
        );

        isError = result.IsError;
        paymentUrl = result.Value;
        retries--;
    }

    if (isError) return null;

    return paymentUrl;
}

export async function fetchPaymentStatus(idAppointment) {
    let queryString = `?idAppointment=${idAppointment}`;

    const result = await http.get(
        apiEndpoint() + "FetchPaymentStatus" + queryString
    );

    if (result.NoContent) return "pending";

    if (result.IsError) return result;

    if (result.Value && result.Value.indexOf("paid") !== -1) {
        return "paid";
    }

    return result.Value;
}

export async function fetchFutureAppointment(idAppointment) {
    const queryString = `?idAppointment=${idAppointment}`;

    const result = await http.post(
        apiEndpoint() + "FetchFutureAppointment" + queryString
    );

    return result.Value;
}

export async function cancelAppointment(idAppointment) {
    const queryString = `?idAppointment=${idAppointment}`;

    const result = await http.post(
        apiEndpoint() + "CancelAppointment" + queryString
    );

    return result;
}

export default {
    generateTreatmentList,
    treatmentsSelected,
    treatmentsPriceTotal,
    treatmentsDepositTotal,
    isTreatmentSelected,
    translateDescription,
    translateRemark,
    treatmentDescription,
    fetchTreatments,
    fetchAvailabilitiesDay,
    fetchAvailabilitiesShort,
    fetchAvailabilitiesMonth,
    createAppointment,
    changeAppointment,
    fetchPaymentMethods,
    payAppointment,
    fetchPaymentStatus,
    fetchFutureAppointment,
    cancelAppointment
};

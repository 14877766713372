import React, { Component } from "react";
import { Trans } from "react-i18next";
import {
    fetchPaymentMethods,
    treatmentsPriceTotal
} from "../../services/appointmentService";
import Section, { Steps } from "./section";
import PaymentMethod from "./paymentMethod";
import Loader from "./loader";
import Warning from "./warning";

const { clientWebsite, redirectAfterAppointment } = window.env;

class AppointmentConfirmed extends Component {
    state = { paymentMethods: [], selectedPaymentMethod: "ideal" };

    async componentDidMount() {
        this.loadPaymentMethods();
    }

    loadPaymentMethods = async () => {
        if (!this.state.paymentMethods || this.state.paymentMethods.length === 0) {
            this.setState({ paymentMethods: ["ideal"] });

            if (!window.env.payment) return;

            fetchPaymentMethods().then(paymentMethods => {
                if (paymentMethods && paymentMethods.length > 0)
                    this.setState({
                        paymentMethods,
                        selectedPaymentMethod: paymentMethods[0]
                    });
            });
        }
    };

    selectPaymentMethod = paymentMethod => {
        this.setState({ selectedPaymentMethod: paymentMethod });
    };

    render() {
        const {
            currentStep,
            availability,
            customer,
            passby,
            treatments,
            confirming,
            idAppointment,
            resultCreateAppointment,
            onChange,
            onRetry,
            onConfirm
        } = this.props;

        let { amountDeposit } = this.props;

        // If a customer logs in at a later time, checks if it is a diamond or star customer and if so skip the deposit amount.
        if (customer && (customer.IsDiamond || customer.IsStar)) amountDeposit = 0;

        // If the appointment is changed no deposit is needed
        if (idAppointment) amountDeposit = 0;

        let date = "";
        let time = "";
        let firstName = "";
        let email = "";
        const payment = window.env.payment;

        if (availability) {
            date = availability.DateTime.format("dddd LL");
            time = availability.DateTime.format("HH:mm");
        }

        if (customer) {
            firstName = customer.FirstName;
            email = customer.Email;
        } else if (passby) {
            firstName = passby.Name;
            email = passby.Email;
        }

        const { debug } = window.env;
        const { paymentMethods, selectedPaymentMethod } = this.state;

        const priceTotal = treatmentsPriceTotal(treatments);

        const isCreating = confirming || !resultCreateAppointment;
        const isConfirming = !resultCreateAppointment && !confirming;
        const isChecking = !resultCreateAppointment && confirming;
        const isCreated =
            resultCreateAppointment &&
            !resultCreateAppointment.IsError &&
            !confirming;
        const isPending = isCreated && amountDeposit !== 0;
        const isSuccess = isCreated && amountDeposit === 0;
        const isPaymentFailed = resultCreateAppointment && resultCreateAppointment.PaymentFailed;

        if (redirectAfterAppointment && isSuccess) {
            window.location.replace(redirectAfterAppointment);
            return null;
        }

        return (
            <Section
                id="confirmed"
                title={
                    isConfirming
                        ? "appointment-confirmed.header.default"
                        : isChecking
                            ? "appointment-confirmed.header.checking"
                            : isPending
                                ? "appointment-confirmed.header.pending"
                                : isSuccess
                                    ? idAppointment
                                        ? "appointment-confirmed.header.changed"
                                        : "appointment-confirmed.header.confirmed"
                                    : "appointment-confirmed.header.failed"
                }
                step={Steps.Confirmed}
                currentStep={currentStep}
                onChange={onChange}
            >
                {isCreating ? (
                    <Loader />
                ) : resultCreateAppointment.IsError ? (
                    <React.Fragment>
                        <Warning custom={true}>
                            <p>
                                <Trans i18nKey="appointment-confirmed.failed.header">
                                    <strong>
                                        (Welkom terug {{ firstName }}!)<br />
                                        <br />Het is niet gelukt de afspraak op {{ date }} om{" "}
                                        {{ time }} te maken.
                                    </strong>
                                </Trans>
                            </p>
                        </Warning>
                        <p>
                            {!isPaymentFailed ? (
                                <Trans i18nKey="error.appointment">
                                    Waarschijnlijk heeft iemand anders al een afspraak op dit
                                    tijdstip gemaakt.
                                </Trans>) : (
                                <Trans i18nKey="payment.description.failed" />)}
                        </p>
                        {debug && <p>Details: {resultCreateAppointment.ErrorMessage}</p>}
                        <p>
                            <button role="link" onClick={() => onRetry()}>
                                <Trans i18nKey="label.retry">Probeer opnieuw</Trans>
                            </button>
                        </p>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p>
                            <Trans
                                i18nKey={
                                    isPending
                                        ? "appointment-confirmed.pending.header"
                                        : "appointment-confirmed.success.header"
                                }
                            >
                                <strong>
                                    (Welkom terug {{ firstName }}!)<br />
                                    <br />Je afspraak op {{ date }} om {{ time }} is bevestigd.
                                </strong>
                            </Trans>
                        </p>
                        <div>
                            {isPending ? (
                                <React.Fragment>
                                    <Trans i18nKey="appointment-confirmed.pay.method">
                                        <h2>Kies je betaalmethode.</h2>
                                    </Trans>

                                    <div id="paymentOptions">
                                        {paymentMethods.map(paymentMethod => (
                                            <PaymentMethod
                                                key={paymentMethod}
                                                paymentMethod={paymentMethod}
                                                selectedPaymentMethod={selectedPaymentMethod}
                                                selectPaymentMethod={this.selectPaymentMethod}
                                                resultCreateAppointment={resultCreateAppointment}
                                                amountDeposit={amountDeposit}
                                                priceTotal={priceTotal}
                                                customer={customer}
                                                passby={passby}
                                            />
                                        ))}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <Trans i18nKey="appointment-confirmed.success.message">
                                    {{ email }}
                                </Trans>
                            )}
                        </div>
                        <Trans i18nKey="appointment-confirmed.contact">
                            <p>
                                Vragen? Neem gerust
                                <a href="/contact">contact</a> op.
                            </p>
                        </Trans>
                        {!customer ? (
                            <Trans i18nKey="appointment-confirmed.account">
                                <p>
                                    appointment-confirmed.account<a href="/registreer">account</a>.
                                </p>
                            </Trans>
                        ) : null}

                        <p>
                            <button role="link" onClick={() => onConfirm()}>
                                <Trans i18nKey="label.appointment.again">
                                    Maak nog een afspraak
                                </Trans>
                            </button>
                        </p>

                        {!idAppointment && payment && !isPending ? (
                            <React.Fragment>
                                <Trans i18nKey="appointment-confirmed.pay.header">
                                    <h2>Betaal later in de salon, of nu online</h2>
                                </Trans>
                                <Trans i18nKey="appointment-confirmed.pay.method">
                                    <p>Kies je betaalmethode.</p>
                                </Trans>

                                <div id="paymentOptions">
                                    {paymentMethods.map(paymentMethod => (
                                        <PaymentMethod
                                            key={paymentMethod}
                                            paymentMethod={paymentMethod}
                                            selectedPaymentMethod={selectedPaymentMethod}
                                            selectPaymentMethod={this.selectPaymentMethod}
                                            resultCreateAppointment={resultCreateAppointment}
                                            priceTotal={priceTotal}
                                            customer={customer}
                                            passby={passby}
                                        />
                                    ))}
                                </div>
                            </React.Fragment>
                        ) : null}
                        <hr />
                        <a href={clientWebsite} id="back">
                            <Trans i18nKey="navigation.gobacktowebsite">
                                Terug <span>naar de website</span>
                            </Trans>
                        </a>
                    </React.Fragment>
                )}
            </Section>
        );
    }
}

export default AppointmentConfirmed;

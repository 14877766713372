import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import Section, { Steps } from "./section";
import i18next from "i18next";
import hairstyles from "../../services/hairstyles";

class Hairstyle extends Component {
  state = {};

  render() {
    const { onChange, onSelect, customer, currentStep } = this.props;
    const { disableHairLength, hasMultipleBranches } = window.env;

    let { hairstyle } = this.props;

    return (
      <Section
        id="gender"
        title="hairstyle.header"
        step={Steps.Hairstyle}
        currentStep={currentStep}
        description={hairstyle ? i18next.t(hairstyle.Description) : null}
        onChange={onChange}
      >
        {!customer &&
          !hasMultipleBranches && (
            <React.Fragment>
              <div className="message note">
                <svg className="shape-icon-message-note">
                  <use xlinkHref="#shape-icon-message-note" />
                </svg>{" "}
                <Trans i18nKey="account.existing">
                  Al een account bij ons?
                </Trans>
                &nbsp;
                <Link to="/inloggen">
                  <Trans i18nKey="label.login">Inloggen</Trans>
                </Link>
              </div>
            </React.Fragment>
          )}
        <ul>
          <li id="gender-male">
            <button
              className="transparent"
              onClick={() =>
                onSelect(
                  (hairstyle = hairstyles.filter(f => f.Indicator === "M")[0])
                )
              }
            >
              <svg className="shape-icon-gender-male">
                <use xlinkHref="#shape-icon-gender-male" />
              </svg>
              <Trans i18nKey="hairstyle.male">
                <h3>Ik ben man</h3>
              </Trans>
            </button>
          </li>
          <li id="gender-female">
            {disableHairLength && (
              <button
                className="transparent"
                onClick={() =>
                  onSelect(
                    (hairstyle = hairstyles.filter(f => f.Indicator === "F")[0])
                  )
                }
              >
                <svg className="shape-icon-gender-female">
                  <use xlinkHref="#shape-icon-gender-female" />
                </svg>
                <Trans i18nKey="hairstyle.female">
                  <h3>Ik ben vrouw</h3>
                </Trans>
              </button>
            )}
            {!disableHairLength && (
              <button className="transparent">
                <svg className="shape-icon-gender-female">
                  <use xlinkHref="#shape-icon-gender-female" />
                </svg>
                <Trans i18nKey="hairstyle.female">
                  <h3>Ik ben vrouw</h3>
                </Trans>
              </button>
            )}

            {!disableHairLength && (
              <ul id="hair-length">
                <li>
                  <button
                    className="transparent"
                    onClick={() =>
                      onSelect(
                        (hairstyle = hairstyles.filter(
                          f => f.Indicator === "FS"
                        )[0])
                      )
                    }
                  >
                    <svg className="shape-icon-gender-female-short">
                      <use xlinkHref="#shape-icon-gender-female-short" />
                    </svg>{" "}
                    <Trans i18nKey="hairstyle.short">Kort haar</Trans>
                  </button>
                </li>
                <li>
                  <button
                    className="transparent"
                    onClick={() =>
                      onSelect(
                        (hairstyle = hairstyles.filter(
                          f => f.Indicator === "FM"
                        )[0])
                      )
                    }
                  >
                    <svg className="shape-icon-gender-female-mid">
                      <use xlinkHref="#shape-icon-gender-female-mid" />
                    </svg>{" "}
                    <Trans i18nKey="hairstyle.mid">Tot op de schouders</Trans>
                  </button>
                </li>
                <li>
                  <button
                    className="transparent"
                    onClick={() =>
                      onSelect(
                        (hairstyle = hairstyles.filter(
                          f => f.Indicator === "FL"
                        )[0])
                      )
                    }
                  >
                    <svg className="shape-icon-gender-female-long">
                      <use xlinkHref="#shape-icon-gender-female-long" />
                    </svg>{" "}
                    <Trans i18nKey="hairstyle.long">Over de schouders</Trans>
                  </button>
                </li>
              </ul>
            )}
          </li>
        </ul>

        <div id="gender-other">
          <button
            className="transparent"
            onClick={() =>
              onSelect(
                (hairstyle = hairstyles.filter(f => f.Indicator === "O")[0])
              )
            }
          >
            <Trans i18nKey="hairstyle.nonbinary">
              <h3>Anders / wil ik niet zeggen</h3>
            </Trans>
          </button>
        </div>
      </Section>
    );
  }
}

export default Hairstyle;

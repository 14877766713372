import React, { Component } from "react";
import { Trans } from "react-i18next";
import Loader from "./loader";
import Section, { Steps } from "./section";
import Warning from "./warning";
import employeeService from "../../services/employeeService";
import i18next from "i18next";

const { showSilentChair } = window.env;


class Employee extends Component {

    state = {
        employees: []
    };

    handleTreatmentSelect() {
        this.setState({ employees: [] });
    }

    handleTreatmentsConfirm(treatments, customer) {
        employeeService
            .fetchEmployees(treatments, customer, this.props.language)
            .then(employees => this.setState({ employees }));
    }

    handleSilentChairChange(event) {
        this.setState({ silentChair: event.target.checked });
    }

    fetchEmployee(idEmployee) {
        const employees = this.state.employees;

        if (!employees || employees === null) return [];

        const employee = employees.find(f => f.Id === idEmployee);

        return employee;
    }

    render() {
        const { currentStep, employee, onChange, onSelect } = this.props;
        const { employees, silentChair } = this.state;

        return (
            <Section
                id="employee"
                step={Steps.Employee}
                currentStep={currentStep}
                description={
                    employee
                        ? employee.IsPreferred
                            ? employee.FirstName
                            : i18next.t("employee.no-preference")
                        : null
                }
                onChange={onChange}
            >
                {!employees || employees.IsError ? (
                    <Warning>
                        <Trans i18nKey="employee.none-available">
                            Er zijn geen stylisten beschikbaar voor de gekozen behandeling.
                            Kies een andere behandeling.
                        </Trans>
                    </Warning>
                ) : employees.length === 0 ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <ul>
                            {employees.map(employee => (
                                <li key={employee.Id} className="showPopover">
                                    <div role="link" onClick={() => onSelect(employee, silentChair)}>
                                        <figure>
                                            {employee.PictureUrl && employee.PictureUrl.length > 30 ? (
                                                <img src={employee.PictureUrl} alt="" />
                                            ) : (
                                                <svg className="shape-icon-employee">
                                                    <use xlinkHref="#shape-icon-employee" />
                                                </svg>
                                            )}
                                        </figure>
                                        <h3>{employee.FirstName}</h3>
                                        {employee.Remark && employee.Remark.length > 5 ? (
                                            <h3>{employee.Remark}</h3>
                                        ) : null}
                                    </div>
                                    {employee.DidLastVisit || employee.DateFurloughUntill ? (
                                        <div className="popover">
                                            {employee.DidLastVisit ? (
                                                <p>
                                                    <Trans i18nKey="employee.last-visit">
                                                        {{ employeeFirstName: employee.FirstName }}
                                                    </Trans>
                                                </p>
                                            ) : null}
                                            {employee.DateFurloughUntill ? (
                                                <p>
                                                    <svg className="shape-icon-message-note">
                                                        <use xlinkHref="#shape-icon-message-note" />
                                                    </svg>{" "}
                                                    <Trans i18nKey="employee.furlough">
                                                        {{
                                                            employeeDateFurlough: employee.DateFurloughUntill
                                                        }}
                                                    </Trans>
                                                </p>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </li>
                            ))}
                        </ul>
                        {showSilentChair && (
                            <ul>
                                <li style={{ display: "flex" }}>
                                    <input
                                        key="checkBoxSilentChair"
                                        id="checkBoxSilentChair"
                                        type="checkbox"
                                        name="checkBoxSilentChair"
                                        defaultChecked={false}
                                        onChange={event => this.handleSilentChairChange(event)}
                                    />
                                    <label style={{ marginTop: "5px" }} htmlFor="checkBoxSilentChair">
                                        <Trans i18nKey="employee.silent-chair">
                                            Silent Chair
                                        </Trans>
                                    </label>
                                </li>
                            </ul>
                        )}
                    </React.Fragment>
                )}
            </Section>
        );
    }
}

export default Employee;

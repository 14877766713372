import React, { Component } from "react";
import { Trans } from "react-i18next";
//import ReactGA from "react-ga";
import Collapse from "@kunukn/react-collapse";

export const Steps = {
  Branch: 1,
  Hairstyle: 2,
  Treatment: 3,
  Employee: 4,
  Appointment: 5,
  ReturningOrNew: 6,
  Register: 7,
  Confirm: 8,
  Confirmed: 9
};

class Section extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.clicked = false;
  }

  componentDidMount() {
    this.handleChange();
  }

  componentDidUpdate() {
    const { step, currentStep, hidden } = this.props;
    const { collapseStyle } = this.state;

    if (!currentStep) return;

    if (currentStep === step && collapseStyle === "step") {
      this.setState({ collapseStyle: "step open", isOpen: true });
      //console.log(this.props.id + " update: step open");

      return;
    }

    // Only for this step, if the section fails to open when going back.
      if (step === Steps.Register && (collapseStyle === "step open" || (!collapseStyle && hidden))) {
      this.setState({ collapseStyle: "step open ready", isOpen: true });
      return;
    }

    if (currentStep !== step && collapseStyle === "step open ready") {
      this.setState({ collapseStyle: "step", isOpen: false });
      //console.log(this.props.id + " update: step");
    }
  }

  handleChange = collapseState => {
    if (this.props.hidden) return;

    if (!collapseState) {
      this.setState({ collapseStyle: "step" });
      this.clicked = false;
      return;
    }

    let collapseStyle = "step";

    switch (collapseState.state) {
      case "expanding":
      case "collapsing":
        collapseStyle = "step open";
        break;
      case "expanded":
        collapseStyle = "step open ready";
        break;
      case "collapsed":
        collapseStyle = "step";
        break;
      default:
        collapseStyle = "step";
        break;
    }

    this.setState({ collapseStyle });

    if (this.props.onChange && collapseState.state === "expanding") {
      this.props.onChange(this.props.step, collapseState.state, this.clicked);
      //if (window.env.trackingId)
      //  ReactGA.pageview("/appointment/" + this.props.id);
    }

    this.clicked = false;

    //console.log(this.props.id + ": " + collapseStyle);
  };

  onClick = () => {
    const { enabled, step, hidden } = this.props;
    const { isOpen } = this.state;

    this.clicked = false;

    if (enabled === false) return;
    if (hidden === true) return;

    if (step === Steps.Confirm || step === Steps.Confirmed) return;

    if (isOpen) return;

    this.clicked = true;
    this.setState({ isOpen: !isOpen });
    //console.log(this.props.id + " toggle: " + !isOpen);
  };

  render() {
    let { collapseStyle } = this.state;
    const { isOpen } = this.state;
    const { id, step, currentStep, title, description, children } = this.props;

    let hidden = this.props.hidden;
    let disabled = currentStep < step;

    const open = step === currentStep;
    const header = title ? title : id + ".header";
    const changeVisible =
      !isOpen &&
      !disabled &&
      step !== Steps.Confirm &&
      step !== Steps.Confirmed;

    // Confirmed, disable all sections
    if (currentStep === Steps.Confirmed && step !== Steps.Confirmed)
      disabled = true;

    if (step === Steps.ReturningOrNew && currentStep >= Steps.Confirm)
      hidden = true;

    if (collapseStyle && currentStep) {
      if (disabled) collapseStyle = "step disabled";
      else if (collapseStyle.indexOf(" disabled") !== -1)
        collapseStyle = collapseStyle.replace(" disabled", "");

      if (open && collapseStyle.indexOf(" open") === -1)
        collapseStyle += " open";
    }

    if (hidden) collapseStyle = "step hide";

    //console.log(this.props.id + " render: " + collapseStyle);

    return (
      <section id={id} className={collapseStyle} onClick={this.onClick}>
        <header>
          <Trans i18nKey={header}>
            <h2>{header}</h2>
          </Trans>
          {(description ||
            step === Steps.ReturningOrNew ||
            step === Steps.Branch) && <p>{description}</p>}
          {changeVisible && (
            <Trans i18nKey="link.change">
              <div role="link">Wijzigen</div>
            </Trans>
          )}
        </header>

        <Collapse
          className="body"
          style={{ overflow: "show" }}
          isOpen={this.state.isOpen}
          onChange={this.handleChange}
          transition="height 500ms"
        >
          {children}
        </Collapse>
      </section>
    );
  }
}

export default Section;

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export function isValidArray(test, minimalLength = null) {
  if (!test) return false;
  if (test === null) return false;
  if (test === undefined) return false;
  if (test.IsError) return false;
  if (test.constructor !== Array) return false;

  if (minimalLength === null) return true;

  return test.length >= minimalLength;
}

export function toISO(dateTime) {
  return dateTime.format("YYYYMMDDTHHmmss%2b0000");
}

export function fromISO(dateTime) {
  return dayjs(dateTime, "YYYYMMDDTHHmmss%2b0000");
}

export function formatPrice(price) {
  return (price * 0.01).toFixed(2).replace(".", ",");
}

export default {
  isValidArray,
  toISO,
  fromISO,
  formatPrice
};

import React, { Component } from "react";
import Section, { Steps } from "./section";
import { Trans } from "react-i18next";
import LoginForm from "../forms/login";

class ReturningOrNew extends Component {
  state = {};
  render() {
    const {
      currentStep,
      language,
      countries,
      customer,
      customerNew,
      location,
      onChange,
      onLogin,
      onNewCustomer
    } = this.props;

    const hidden = customer;

    return (
      <React.Fragment>
        <Section
          id="returning-new"
          title="appointment.returning-new.header"
          step={Steps.ReturningOrNew}
          currentStep={currentStep}
          hidden={hidden}
          onChange={onChange}
        >
          {hidden ? null : (
            <React.Fragment>
              {!customerNew && (
                <div id="returning-customer">
                  <Trans i18nKey="appointment.returning-customer.header">
                    <strong>Al bekend hier?</strong>
                  </Trans>
                  <LoginForm
                    language={language}
                    countries={countries}
                    location={location}
                    onLogin={onLogin}
                  />
                </div>
              )}
              <div id="new-customer">
                <Trans i18nKey="appointment.new-customer.register">
                  <strong>Geen account?</strong>
                  <p>Maak binnen 2 minuten een account aan.</p>
                  <button onClick={() => onNewCustomer()}>
                    Maak een account aan
                  </button>
                </Trans>
                <hr />
                <aside>
                  <Trans i18nKey="appointment.new-customer.why-register.header">
                    <h3>Waarom een account?</h3>
                  </Trans>
                  <ul>
                    <Trans i18nKey="appointment.new-customer.why-register.list">
                      <li>sneller afspraken maken</li>
                      <li>inzicht in je afspraakgeschiedenis</li>
                      <li>op de hoogte via optionele nieuwsbrief</li>
                    </Trans>
                  </ul>
                </aside>
              </div>
            </React.Fragment>
          )}
        </Section>
      </React.Fragment>
    );
  }
}

export default ReturningOrNew;

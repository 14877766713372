import "./polyfills";
import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
//import "element-remove";
import "./index.css";
import "./App.scss";
import App from "./App";
import i18n from "./i18n";
//import withTracker from "./withTracker";
import { I18nextProvider } from "react-i18next";
import logger from "./services/logService";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import { registerLicense } from '@syncfusion/ej2-base';
//import registerServiceWorker from "./registerServiceWorker";

logger.init();

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bdkJjXnpbcXRWR2Zc');

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Suspense fallback="Laden vertalingen....">
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<App />} />
                </Routes>
            </BrowserRouter>
        </I18nextProvider>{" "}
    </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregisterServiceWorker();
//registerServiceWorker();

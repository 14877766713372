import http from "./httpService";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { fromISO, isValidArray } from "../components/common/check";

const { apiUrl, license, debug } = window.env;
const apiEndpoint = !debug
    ? apiUrl + "/customer/" + (window.branch ? window.branch : license) + "/"
    : "https://localhost:44357/CustomerService.svc/" + license + "/";
const key = "customer";

dayjs.extend(localizedFormat);
dayjs.extend(utc);

export async function login(user, language, source) {
    if (!user || (!user.token && !user.email)) return null;

    if (!user.token) {
        if (
            !user.email ||
            !user.email.length > 4 ||
            !user.password ||
            !user.password.length > 3
        )
            return null;
    }

    const credentials = user.token
        ? { token: user.token }
        : {
            userName: user.email,
            password: user.password
        };

    const { IsError: isError, Value: customer } = await http.post(
        apiEndpoint + "Login",
        credentials,
        source
    );

    if (isError) return null;

    dayjs.locale(language);

    if (
        customer &&
        customer.FutureAppointments &&
        customer.FutureAppointments.length > 0
    ) {
        for (let futureAppointment of customer.FutureAppointments) {
            if (futureAppointment.DateTimeFrom) {
                futureAppointment.DateFormatted = dayjs
                    .utc(futureAppointment.DateTimeFrom)
                    .local()
                    .format("dddd LL");
                futureAppointment.TimeFormatted = dayjs
                    .utc(futureAppointment.DateTimeFrom)
                    .local()
                    .format("HH:mm");
            }
        }
    }

    if (customer) {
        formatTelephoneMobile(customer);

        if (customer.LastVisit) {
            customer.LastVisit = fromISO(customer.LastVisit);
            customer.LastVisitString = customer.LastVisit.format("dddd LL");
        }
        if (customer.Birthday) {
            customer.Birthday = fromISO(customer.Birthday).format("DD-MM-YYYY");
        }
    }

    if (customer !== null) {
        if (user.rememberme) {
            customer.RememberMe = true;
            localStorage.setItem(key, JSON.stringify(customer));
        }

        sessionStorage.setItem(key, JSON.stringify(customer));
    }

    return customer;
}

export async function formatTelephoneMobile(customer, countries) {
    if (!customer || !customer.TelephoneMobile) return;

    customer.TelephoneMobile = customer.TelephoneMobile.replace(/\s+/g, "");

    if (customer.TelephoneMobile.startsWith("+31")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+31", "0");
        customer.TelephoneMobileCountryCode = "NL";
    } else if (customer.TelephoneMobile.startsWith("+32")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+32", "0");
        customer.TelephoneMobileCountryCode = "BE";
    } else if (customer.TelephoneMobile.startsWith("+33")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+33", "0");
        customer.TelephoneMobileCountryCode = "FR";
    } else if (customer.TelephoneMobile.startsWith("+34")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+34", "");
        customer.TelephoneMobileCountryCode = "ES";
    } else if (customer.TelephoneMobile.startsWith("+352")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+352", "");
        customer.TelephoneMobileCountryCode = "LU";
    } else if (customer.TelephoneMobile.startsWith("+41")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+41", "");
        customer.TelephoneMobileCountryCode = "CH";
    } else if (customer.TelephoneMobile.startsWith("+46")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+46", "0");
        customer.TelephoneMobileCountryCode = "SE";
    } else if (customer.TelephoneMobile.startsWith("+43")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+43", "0");
        customer.TelephoneMobileCountryCode = "AT";
    } else if (customer.TelephoneMobile.startsWith("+44")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+44", "0");
        customer.TelephoneMobileCountryCode = "UK";
    } else if (customer.TelephoneMobile.startsWith("+49")) {
        customer.TelephoneMobile = customer.TelephoneMobile.replace("+49", "0");
        customer.TelephoneMobileCountryCode = "DE";
    }

    if (
        !customer.TelephoneMobileCountryCode ||
        customer.TelephoneMobileCountryCode === ""
    ) {
        if (!countries) countries = await fetchCountries();

        if (isValidArray(countries, 1) && countries[0].CountryCode)
            customer.TelephoneMobileCountryCode = countries[0].CountryCode;
    }

    if (
        customer.TelephoneMobileCountryCode === "NL" &&
        customer.TelephoneMobile.startsWith("06") &&
        !customer.TelephoneMobile.startsWith("06-")
    )
        customer.TelephoneMobile = customer.TelephoneMobile.replace("06", "06-");
    // replace only replaces the first occurence.
    else if (
        customer.TelephoneMobileCountryCode === "BE" &&
        (customer.TelephoneMobile.startsWith("045") ||
            customer.TelephoneMobile.startsWith("046") ||
            customer.TelephoneMobile.startsWith("047") ||
            customer.TelephoneMobile.startsWith("048") ||
            customer.TelephoneMobile.startsWith("049"))
    )
        customer.TelephoneMobile = customer.TelephoneMobile.replace(
            customer.TelephoneMobile.substring(0, 4),
            customer.TelephoneMobile.substring(0, 4) + " "
        )
            .replace(
                customer.TelephoneMobile.substring(0, 7),
                customer.TelephoneMobile.substring(0, 7) + " "
            )
            .replace(
                customer.TelephoneMobile.substring(0, 10),
                customer.TelephoneMobile.substring(0, 10) + " "
            );
    // replace only replaces the first occurence.
    else if (
        customer.TelephoneMobileCountryCode === "UK" &&
        customer.TelephoneMobile.startsWith("07") &&
        customer.TelephoneMobile.length === 5
    ) {
        const first = customer.TelephoneMobile.substring(0, 5);
        customer.TelephoneMobile = customer.TelephoneMobile.replace(
            first,
            first + "-"
        );
    }
    // replace only replaces the first occurence.
    else if (
        customer.TelephoneMobileCountryCode === "DE" &&
        customer.TelephoneMobile.startsWith("0151")
    )
        customer.TelephoneMobile = customer.TelephoneMobile.replace(
            "0151",
            "0151-"
        );
    // replace only replaces the first occurence.
    else if (
        customer.TelephoneMobileCountryCode === "DE" &&
        customer.TelephoneMobile.startsWith("0157")
    )
        customer.TelephoneMobile = customer.TelephoneMobile.replace(
            "0157",
            "0157-"
        );
    // replace only replaces the first occurence.
    else if (
        customer.TelephoneMobileCountryCode === "DE" &&
        customer.TelephoneMobile.startsWith("0173")
    )
        customer.TelephoneMobile = customer.TelephoneMobile.replace(
            "0173",
            "0173-"
        );
    // replace only replaces the first occurence.
    else if (customer.TelephoneMobileCountryCode === "+")
        customer.TelephoneMobile = customer.TelephoneMobile.replace(
            "+",
            ""
        );
}

export async function refresh(customer, language, countries) {
    if (!customer) return null;

    const rememberMe = customer.RememberMe;
    const user = customer.AuthenticationToken
        ? { token: customer.AuthenticationToken }
        : {
            email: customer.Email,
            password: customer.Zip,
            token: customer.AuthenticationToken
        };

    customer = await login(user, language, countries);

    if (!customer) return null;

    formatTelephoneMobile(customer, countries);

    if (customer.CardInformation) {
        const cardInformation = customer.CardInformation;

        if (cardInformation && cardInformation.DateExpire) {
            cardInformation.DateExpire = fromISO(cardInformation.DateExpire);

            const date = dayjs.utc(cardInformation.DateExpire).local();
            if (date > dayjs().add(10, "years")) cardInformation.DateExpire = null;
            else cardInformation.DateExpire = date;
        }
    }

    if (rememberMe) {
        customer.RememberMe = true;
        localStorage.setItem(key, JSON.stringify(customer));
    }

    sessionStorage.setItem(key, JSON.stringify(customer));

    return customer;
}

export async function fetchFamilyMembers(customer) {
    if (!customer) return [];

    //customer.FutureAppointments = null;

    const customerLoad = getCustomerLoad(customer);
    if (
        !customerLoad ||
        !customerLoad.Zip ||
        !customerLoad.HouseNumber ||
        customerLoad.Zip.length < 6 ||
        customerLoad.HouseNumber.length < 1
    )
        return [];

    const { IsError: isError, Value: customers } = await http.post(
        apiEndpoint + "FetchFamilyMembers",
        {
            customer: customerLoad
        }
    );

    if (isError) return [];

    return customers;
}

export async function fetchCountries() {
    const { IsError: isError, Value: countries } = await http.get(
        apiEndpoint + "FetchCountries"
    );

    if (isError) return null;
    if (!isValidArray(countries, 1)) return countries;

    for (let country of countries) {
        country.Indicator = country.CountryCode;
    }

    return countries;
}

function getCustomerLoad(customer) {
    const customerLoad = { ...customer };
    customerLoad.LastColor = null;
    customerLoad.LastVisit = null;
    customerLoad.FutureAppointments = null;
    customerLoad.CardInformation = null;
    customerLoad.Subscriptions = null;

    return customerLoad;
}

export async function isRegistered(email) {
    if (!email || email.trim().length < 4) return null;

    const { IsError: isError, Value: result } = await http.get(
        apiEndpoint + "IsRegistered?email=" + email
    );

    if (isError) return null;

    return result;
}

export async function isRegisteredOnAddress(
    indicatorGender,
    initials,
    firstName,
    lastName,
    zipCode,
    houseNumber,
    country,
    email
) {
    if (
        !zipCode ||
        zipCode.trim().length < 4 ||
        !houseNumber ||
        houseNumber.length < 1 ||
        indicatorGender === "-1" ||
        !lastName ||
        lastName.length < 1
    )
        return null;

    let queryString = `?indicatorGender=${indicatorGender}&initials=${initials}&firstName=${firstName}&lastName=${lastName}&zipCode=${zipCode}&houseNumber=${houseNumber}`;
    if (country && country.trim().length > 0)
        queryString = queryString + `&country=${country}`;

    if (email && email.trim().length > 0)
        queryString = queryString + `&email=${email}`;

    const { IsError: isError, Value: result } = await http.get(
        apiEndpoint + "IsRegisteredOnAddress" + queryString
    );

    if (isError) return null;

    return result;
}

export async function modify(customer) {
    if (!customer) return null;

    //customer.FutureAppointments = null;

    const customerLoad = getCustomerLoad(customer);

    const { IsError: isError, Value: result } = await http.post(
        apiEndpoint + "Modify?idCustomer=" + customer.Id,
        {
            idCustomer: customer.Id,
            customerModified: customerLoad
        }
    );

    if (isError) return null;

    if (result !== null) {
        sessionStorage.setItem(key, JSON.stringify(result));
        if (customer.RememberMe) localStorage.setItem(key, JSON.stringify(result));
    }

    return result;
}

export async function create(customer) {
    if (!customer) return null;

    const { IsError: isError, Value: result } = await http.post(
        apiEndpoint + "Create",
        {
            customer
        }
    );

    if (isError) return { IsError: isError, Message: result };

    if (result !== null) sessionStorage.setItem(key, JSON.stringify(result));

    return result;
}

export async function loginRegisteredCustomer(customer) {
    sessionStorage.setItem(key, JSON.stringify(customer));
}

export function logout() {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
}

export async function forgotPassword(email) {
    if (!email) return null;

    const { IsError: isError, Value: result } = await http.post(
        apiEndpoint + "LostPassword",
        {
            email
        }
    );

    if (isError) return null;

    return result;
}

export async function updatePassword(guid, password, passwordOld) {
    if (!guid || !password) return null;

    const { IsError: isError, Value: result } = await http.post(
        apiEndpoint + "UpdatePassword",
        {
            guid,
            password,
            passwordOld
        }
    );

    if (isError) return null;

    return result;
}

export function getCurrentCustomer() {
    try {
        const item = sessionStorage.getItem(key) || localStorage.getItem(key);
        return JSON.parse(item);
    } catch (exception) {
        return null;
    }
}

export function getMobileTelephoneMask(countryCode) {
    let customMaskMobilePhone = "06-99999999";

    switch (countryCode) {
        case "UK":
        case "GB":
            customMaskMobilePhone = "07999-999999";
            break;
        case "BE":
        case "B":
            customMaskMobilePhone = "0499 99 99 99";
            break;
        case "DE":
        case "D":
            customMaskMobilePhone = "0199-9999999";
            break;
        case "LU":
        case "L":
            customMaskMobilePhone = "691 999 999";
            break;
        case "FR":
        case "F":
            customMaskMobilePhone = "09 99 99 99 99";
            break;
        case "ES":
            customMaskMobilePhone = "999 999 999";
            break;
        case "AT":
            customMaskMobilePhone = "0699999999999";
            break;
        case "SE":
            customMaskMobilePhone = "079 99999999";
            break;
        case "+":
            customMaskMobilePhone = "999999999999";
            break;
        default:
            break;
    }

    return customMaskMobilePhone;
}

export default {
    login,
    refresh,
    formatTelephoneMobile,
    loginRegisteredCustomer,
    logout,
    forgotPassword,
    updatePassword,
    getCurrentCustomer,
    fetchFamilyMembers,
    fetchCountries,
    isRegistered,
    isRegisteredOnAddress,
    modify,
    create,
    getMobileTelephoneMask
};

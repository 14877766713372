import React from "react";
import Joi from "joi";
import Form from "../common/form";
import { toast } from "react-toastify";
import { treatmentDescription } from "../../services/appointmentService";
import { Trans } from "react-i18next";
import i18next from "i18next";
import shortMessageService from "../../services/shortMessageService";
import Section, { Steps } from "./section";
import Warning from "./warning";

const { disableRemark } = window.env;

class AppointmentConfirm extends Form {
    state = {
        data: {
            validationCode: "",
            remark: ""
        },
        errors: {},
        time: {},
        seconds: 59
    };

    schema = Joi.object({
        validationCode: Joi.number()
            .integer()
            .required()
            .min(100000)
            .max(999999)
            .label("label.sixdigits"),
        remark: Joi.any().label("label.remark")
    });

    constructor(props) {
        super(props);
        this.confirmScroll = React.createRef();
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    componentDidUpdate() {
        if (this.state.disableValidation) return;

        const { customer, idAppointment } = this.props;
        let { amountDeposit } = this.props;

        // If a customer logs in at a later time, checks if it is a diamond or star customer and if so skip the deposit amount.
        if (customer && (customer.IsDiamond || customer.IsStar)) amountDeposit = 0;

        // If a treatment requires a deposit, no validation is needed.
        if (amountDeposit) {
            this.setState({ disableValidation: true });
            return;
        }

        // If the appointment is changed, no validation is needed
        if (idAppointment) {
            this.setState({ disableValidation: true });
            return;
        }

        if (customer || window.env.disableSms)
            this.setState({ disableValidation: true });
        else if (
            this.props.passby &&
            this.props.passby.ValidationGuid &&
            !this.state.sendValidationCodeRetry
        ) {
            setTimeout(() => {
                this.setState({ sendValidationCodeRetry: true });
            }, 20000);
        }
    }

    componentDidMount() {
        const timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    secondsToTime(secs) {
        const hours = Math.floor(secs / (60 * 60));

        const divisor_for_minutes = secs % (60 * 60);
        const minutes = Math.floor(divisor_for_minutes / 60);

        const divisor_for_seconds = divisor_for_minutes % 60;
        const seconds = Math.ceil(divisor_for_seconds);

        const obj = {
            h: hours,
            m: minutes,
            s: seconds
        };

        return obj;
    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    stopTimer() {
        this.setState({ time: {}, seconds: 59 });
        clearInterval(this.timer);
        this.timer = 0;
    }

    countDown() {
        if (this.props.currentStep !== Steps.Confirm) return;

        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds
        });

        // Check if we're at zero.
        if (seconds === 0) {
            this.doSubmit();
        }
    }

    handleLeavePage(e) {
        const confirmationMessage = i18next.t("appointment-confirm.exit");
        e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
    }

    onSendValidationCodeRetry() {
        if (this.props.passby)
            shortMessageService.requestValidationCodeRetry(
                this.props.passby.ValidationGuid,
                this.props.passby.Telephone,
                this.props.passby.TelephoneCountryCode
            );

        this.setState({ sendValidationCodeRetry: false });
    }

    doChange = async input => {
        if (input && input.name === "remark") {
            this.stopTimer();
        }

        if (!(input && input.name === "validationCode")) return;

        const { errors } = this.state;
        this.setState({ sendValidationCodeRetry: false });

        if (input.value.length === 6) {
            const isCodeValid = await shortMessageService.isCodeValid(
                this.props.passby
                    ? this.props.passby.ValidationGuid
                    : this.props.validationGuid,
                input.value
            );

            if (isCodeValid) {
                toast.success(i18next.t("appointment-confirm.code.valid"));
                this.setState({ validationCode: input.value, isCodeValid });
            } else {
                errors[input.name] = "appointment-confirm.code.invalid";
                this.setState({ errors });
            }
        }
    };

    doSubmit = async () => {
        const { data, validationCode, disableValidation, isCodeValid } = this.state;
        const { passby, onConfirm } = this.props;
        const validationGuid = passby
            ? passby.ValidationGuid
            : this.props.validationGuid;

        if (!disableValidation && !isCodeValid) {
            toast.error(i18next.t("appointment-confirm.code.invalid"));

            return;
        }

        onConfirm(
            data.remark,
            passby,
            validationCode ? validationCode : validationGuid,
            this.props.language,
            this.props.idAppointment
        );

        data.validationCode = "";
        data.remark = "";

        this.setState({ data, isCodeValid: false });
        this.stopTimer();

        window.removeEventListener("beforeunload", this.handleLeavePage);
    };

    scrollTo = () => {
        if (this.confirmScroll && this.confirmScroll.current)
            this.confirmScroll.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
    };

    render() {
        const { disclaimerUrl, privacyUrl } = window.env;

        const {
            currentStep,
            customer,
            treatments,
            employee,
            availability,
            language,
            idAppointment,
            onChange
        } = this.props;

        const {
            isCodeValid,
            disableValidation,
            sendValidationCodeRetry
        } = this.state;

        let date = "";
        let time = "";
        const description = treatments
            ? treatmentDescription(treatments, language)
            : null;

        if (availability) {
            date = availability.DateTime.format("LL");
            time = availability.DateTime.format("HH:mm");
        } else {
            return (
                <Section
                    id="check-appointment"
                    title={
                        idAppointment
                            ? "appointment-change-confirm.header"
                            : "appointment-confirm.header"
                    }
                    step={Steps.Confirm}
                    currentStep={currentStep}
                    onChange={onChange}
                />
            );
        }

        if (currentStep === Steps.Confirm) {
            this.scrollTo();
            window.addEventListener("beforeunload", this.handleLeavePage);
        }

        if (isCodeValid || disableValidation) this.startTimer();

        const confirmLabel =
            i18next.t(
                idAppointment ? "label.appointment.change" : "label.appointment.confirm"
            ) +
            (this.state.time.s > 0 && this.state.time.s < 59
                ? " (" + this.state.time.s + ")"
                : "");

        return (
            <Section
                id="check-appointment"
                title={
                    idAppointment
                        ? "appointment-change-confirm.header"
                        : "appointment-confirm.header"
                }
                step={Steps.Confirm}
                currentStep={currentStep}
                onChange={onChange}
            >
                <form onSubmit={this.handleSubmit}>
                    {!customer &&
                        (!isCodeValid && !disableValidation) && (
                            <Warning custom={true}>
                                <div>
                                    <Trans i18nKey="appointment-confirm.code.header">
                                        <p>
                                            Let op! Er is zojuist per SMS een bevestigingscode naar
                                            het opgegeven telefoonnummer verstuurd.
                                        </p>
                                    </Trans>
                                    {this.renderInput(
                                        "validationCode",
                                        "appointment-confirm.code.input",
                                        {
                                            showLabel: false,
                                            useDiv: false,
                                            maxLength: 6
                                        }
                                    )}
                                    {sendValidationCodeRetry && (
                                        <React.Fragment>
                                            <div>
                                                <Trans i18nKey="appointment-confirm.code.not-received">
                                                    <p>Geen code ontvangen?</p>
                                                    <span
                                                        role="link"
                                                        onClick={() => this.onSendValidationCodeRetry()}
                                                    >
                                                        Stuur code opnieuw
                                                    </span>
                                                </Trans>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </Warning>
                        )}
                    {(customer || isCodeValid || disableValidation) && (
                        <React.Fragment>
                            <Trans i18nKey="appointment-confirm.description">
                                <p>
                                    {{ description }} door{" "}
                                    <strong>
                                        {{
                                            employeeFirstName:
                                                employee && employee.IsPreferred
                                                    ? employee.FirstName
                                                    : i18next.t("appointment-confirm.employee-random")
                                        }}
                                    </strong>{" "}
                                    op {{ date }} om {{ time }}.
                                </p>
                            </Trans>
                            {!disableRemark && (
                                <div className="fr">
                                    <Trans i18nKey="appointment-confirm.questions">
                                        <h3>Heb je nog vragen of opmerkingen?</h3>
                                    </Trans>
                                    {this.renderTextArea("remark", "label.remark", {
                                        maxLength: 140
                                    })}
                                </div>
                            )}
                            <div className="fr">
                                <label>
                                    <Trans i18nKey="appointment-confirm.terms-and-privacy">
                                        <a target="_blank" rel="noopener noreferrer" href={disclaimerUrl}>
                                            algemene voorwaarden
                                        </a>{" "}
                                        en{" "}
                                        <a target="_blank" rel="noopener noreferrer" href={privacyUrl}>
                                            privacyverklaring
                                        </a>
                                        {"."}
                                    </Trans>
                                </label>
                            </div>

                            {this.renderButton(
                                confirmLabel,
                                false,
                                true,
                                isCodeValid || disableValidation ? this.confirmScroll : null
                            )}
                        </React.Fragment>
                    )}
                </form>
            </Section>
        );
    }
}

export default AppointmentConfirm;

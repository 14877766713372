import http from "./httpService";
/*import { throwError } from "./logService";*/

const { apiUrl, license, debug } = window.env;
const apiEndpoint = !debug
  ? apiUrl + "/branch/" + license + "/"
  : "https://localhost:44357/BranchService.svc/" + license + "/";

export async function fetch(address) {
  try {
    const { IsError: isError, Value: locations } = await http.get(
      apiEndpoint +
        "Fetch" +
        (address ? "?myAddress=" + address.replace(" ", "%20") : "")
    );
    if (isError) return [];

    return locations;
  } catch {
    setTimeout(() => {}, 3000);

    try {
      const { IsError: isError, Value: locations } = await http.get(
        apiEndpoint +
          "Fetch" +
          (address ? "?myAddress=" + address.replace(" ", "%20") : "")
      );
      if (isError) return [];

      return locations;
    } catch {
      return [];
    }
  }
}

export async function fetchParameters() {
  const result = await http.get(apiEndpoint + "FetchParameters", 5000, true);

  // Strip all HTML tags
  if (
    result &&
    result.Value &&
    result.Value.OnlineStartText &&
    result.Value.OnlineStartText.length > 10
  ) {
    result.Value.OnlineStartText = result.Value.OnlineStartText.replace(
      /(<([^>]+)>)/gi,
      ""
    );
    result.Value.OnlineStartText = result.Value.OnlineStartText.replace(
      /&nbsp;/g,
      " "
    );
  }

  return result.Value;
}

export default {
  fetch,
  fetchParameters
};

import http from "./httpService";

const { giftcard } = window.env;

const { apiUrl, license, debug } = window.env;
const apiEndpoint = !debug
  ? apiUrl + "/giftcard/" + license + "/"
    : "https://localhost:44357/GiftcardService.svc/" + license + "/";

export function getIcon() {
  const today = new Date();
  const currentDate = new Date(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );
  const currentYear = today.getFullYear();

  // Lockdown
  /*
if (
currentDate >= new Date(currentYear + "-01-12") &&
currentDate <= new Date(currentYear + "-02-09")
)
return "shape-icon-corona";
/**/

  // New year
  if (
    (currentDate >= new Date(currentYear + "-12-30") &&
      currentDate <= new Date(currentYear + "-12-31")) ||
    (currentDate >= new Date(currentYear + "-01-01") &&
      currentDate <= new Date(currentYear + "-01-05"))
  )
    return "shape-icon-christmas";

  // Valentines day
  if (
    currentDate >= new Date(currentYear + "-02-04") &&
    currentDate <= new Date(currentYear + "-02-14")
  )
    return "shape-icon-valentine";

  // Kings day
  if (
    currentDate >= new Date(currentYear + "-04-26") &&
    currentDate <= new Date(currentYear + "-04-27")
  )
    return "shape-icon-kingsday";

  // Mothers day
  const dateMayFirst = new Date(currentYear + "-05-01");
  const dayOfWeekMay = dateMayFirst.getUTCDay();

  let firstSundayMay;

  if (dayOfWeekMay === 0) {
    firstSundayMay = dateMayFirst;
  } else {
    firstSundayMay = new Date(dateMayFirst);
    firstSundayMay.setDate(1 + (7 - dayOfWeekMay));
  }

  const secondSundayMay = new Date(firstSundayMay);
  secondSundayMay.setDate(firstSundayMay.getUTCDate() + 7);
  const dateMothersDay = new Date(
    secondSundayMay.getFullYear() +
      "-" +
      (secondSundayMay.getMonth() + 1) +
      "-" +
      secondSundayMay.getDate()
  );

  if (
    currentDate >= new Date(dateMothersDay - 6 * 24 * 60 * 60 * 1000) &&
    currentDate <= dateMothersDay
  )
    return "shape-icon-mama";

  // Fathers day
  const dateJuneFirst = new Date(currentYear + "-06-01");
  const dayOfWeekJune = dateJuneFirst.getUTCDay();

  let firstSundayJune;

  if (dayOfWeekJune === 0) {
    firstSundayJune = dateJuneFirst;
  } else {
    firstSundayJune = new Date(dateJuneFirst);
    firstSundayJune.setDate(1 + (7 - dayOfWeekJune));
  }

  const thirdSundayJune = new Date(firstSundayJune);
  thirdSundayJune.setDate(firstSundayJune.getUTCDate() + 14);
  const dateFathersDay = new Date(
    thirdSundayJune.getFullYear() +
      "-" +
      (thirdSundayJune.getMonth() + 1) +
      "-" +
      thirdSundayJune.getDate()
  );

  if (
    currentDate >= new Date(dateFathersDay - 6 * 24 * 60 * 60 * 1000) &&
    currentDate <= dateFathersDay
  )
    return "shape-icon-papa";

  // Black Friday
  const dateNovemberFirst = new Date(currentYear + "-11-01");
  const dayOfWeekNovember = dateNovemberFirst.getUTCDay();

  let firstFridayNovember;

  if (dayOfWeekNovember === 5) {
    firstFridayNovember = dateNovemberFirst;
  } else {
    firstFridayNovember = new Date(dateNovemberFirst);
    firstFridayNovember.setDate(1 + (5 - dayOfWeekNovember));
  }

  const fourthFridayNovember = new Date(firstFridayNovember);
  fourthFridayNovember.setDate(firstFridayNovember.getUTCDate() + 21);
  const dateBlackFriday = new Date(
    fourthFridayNovember.getFullYear() +
      "-" +
      (fourthFridayNovember.getMonth() + 1) +
      "-" +
      fourthFridayNovember.getDate()
  );

  if (
    currentDate >= new Date(dateBlackFriday - 6 * 24 * 60 * 60 * 1000) &&
    currentDate <= dateBlackFriday
  )
    return "shape-icon-christmas";

  // Sinterklaas
  if (
    currentDate >= new Date(currentYear + "-11-23") &&
    currentDate <= new Date(currentYear + "-12-05")
  )
    return "shape-icon-sinterklaas";

  // Christmas
  if (
    currentDate >= new Date(currentYear + "-12-07") &&
    currentDate <= new Date(currentYear + "-12-27")
  )
    return "shape-icon-christmas";

  return false;
}

export async function fetchCustomAmounts() {
  if (!giftcard) return null;

  const result = await http.get(apiEndpoint + "FetchCustomAmounts");

  if (result.IsError || result.NoContent) return null;

  return result.Value;
}

export async function createGiftcard(
  customer,
  price,
  receiverName,
  receiverEmail,
  senderName,
  senderEmail,
  message,
  redirectUrl,
  paymentMethod
) {
  if (!giftcard) return null;

  const idCustomer = customer ? customer.Id : null;

  const { IsError: isError, Value: status } = await http.post(
    apiEndpoint + "Create",
    {
      price,
      receiverName,
      receiverEmail,
      senderName,
      senderEmail,
      message,
      dateSend: null,
      redirectUrl,
      idCustomer,
      paymentMethod
    }
  );

  if (isError) return null;

  return status;
}

export async function fetchPaymentStatus(idGiftcard) {
  let queryString = `?idGiftcardTransaction=${idGiftcard}`;

  const result = await http.get(
    apiEndpoint + "FetchPaymentStatus" + queryString
  );

  if (result.NoContent) return "pending";

  if (result.IsError) return result;

  if (result.Value && result.Value.indexOf("paid") !== -1) {
    return "paid";
  }

  return result.Value;
}

export default {
  getIcon,
  fetchCustomAmounts,
  createGiftcard,
  fetchPaymentStatus
};
